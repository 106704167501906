import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./LoginPage.css";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useGoogleLogin } from '@react-oauth/google';

function LoginPage({ setLoggedIn }) {
  document.title = "Login";

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form submission from refreshing the page

    try {
      const response = await axios.post('https://sanchidataportal.com/api/login/', { // Update with your API URL
        username: username,
        password: password
      });

      if (response.data.message === 'Login successful') {
        Cookies.set('loggedIn', 'true', { expires: 1, secure: true });
        Cookies.set('security', response.data.security, { expires: 1, secure: true });
        Cookies.set('team', response.data.team, { expires: 1, secure: true });
        Cookies.set('id', response.data.id, { expires: 1, secure: true });
        localStorage.setItem('team', response.data.team);
        setLoggedIn(true);
        navigate('/home');
      } else {
        alert('Invalid credentials. Please enter the correct username and password.');
      }
    } catch (error) {
      alert('Login failed: ' + error.message);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (codeResponse && codeResponse.access_token) {
        axios.post('https://sanchidataportal.com/api/login2/', { "token": codeResponse.access_token })
          .then(response => {
            console.log('Login Success:', codeResponse);
            Cookies.set('loggedIn', 'true', { expires: 1, secure: true });
            Cookies.set('security', response.data.Security, { expires: 1, secure: true });
            Cookies.set('team', response.data.Team, { expires: 1, secure: true });
            Cookies.set('login', response.data.Login, { expires: 1, secure: true });
            localStorage.setItem('login', response.data.Login);
            localStorage.setItem('team', response.data.Team);
            setLoggedIn(true);
            navigate('/home');
          })
      } else {
        console.error('Access token not found in codeResponse:', codeResponse);
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
    }
  });

  return (
    <div className='background-container'>
      <div className='login-wrapper'>
        <div className="form-container">
          <form onSubmit={handleLogin}>
            <h1>Login Page</h1>
            <div className="form-group">
              <label className='label-login' htmlFor="username">Login: </label>
              <input
                type="text"
                id="username"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className='label-login' htmlFor="password">Password: </label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <button className='button-login' type="submit">Login</button>
            </div>
          </form>
          <button onClick={login}>Sign in with Google 🚀</button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;