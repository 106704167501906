import React, { useEffect, useState } from 'react';
import CustomSelect from './CustomSelect';
import axios from 'axios';
import { championPhotos, championData } from "./ChampionsIcon";
import roleIcon from './Role';
import TeamIcons from './TeamIcons';
import Pagination from 'react-bootstrap/Pagination';
import "./TeamDraft.css";
import { useSeason } from './SeasonContext';
import ChampionRoleStats from './ChampionRoleStats';
const DraftsComponent = () => {
    const {currentSeasonTime } = useSeason();

    document.title = "Team Draft";

    const PAGE_SIZE = 12;

    const [championOccurrences, setChampionOccurrences] = useState({});
    const [sortColumn, setSortColumn] = useState('presence');
    const [sortDirection, setSortDirection] = useState('desc');

    const [selectedLeague, setSelectedLeague] = useState([]);
    const [filterLeague, setFilterLeague] = useState([]);
    const [leagueOption, setLeagueOption] = useState([]);

    const [selectedPatch, setSelectedPatch] = useState([]);
    const [filterPatch, setFilterPatch] = useState([]);
    const [patchOption, setPatchOption] = useState([]);

    const [selectedTeam, setSelectedTeam] = useState([]);
    const [filterTeam, setFilterTeam] = useState([]);
    const [teamOption, setTeamOption] = useState([]);

    const [selectedChampion, setSelectedChampion] = useState([]);
    const [filterChampion, setFilterChampion] = useState([]);
    const [championOption, setChampionOption] = useState([]);

    const [selectedTeamSide, setSelectedTeamSide] = useState(["any"]);
    const [filterTeamSide, setFilterTeamSide] = useState(["any"]);

    const [matchData, setMatchData] = useState([]);
    const [filteredMatchData, setFilteredMatchData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [matchesForCurrentPage, setMatchesForCurrentPage] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [blueRoles, setBlueRoles] = useState([
      [[], [], [], [], []],
      [[], [], [], [], []],
      [[], [], [], [], []],
    ]);

    const [redRoles, setRedRoles] = useState([
      [[], [], [], [], []], // Roles for Rotation 1
      [[], [], [], [], []], // Roles for Rotation 1
      [[], [], [], [], []], // Roles for Rotation 1
      [[], [], [], [], []], // Roles for Rotation 1
    ]);

    const [blueBlindCounter, setBlueBlindCounter] = useState([
      [[], [], [], [], []], // Roles for Rotation 1
      [[], [], [], [], []], // Roles for Rotation 1
    ]);

    const [redBlindCounter, setRedBlindCounter] = useState([
      [[], [], [], [], []], // Roles for Rotation 1
      [[], [], [], [], []], // Roles for Rotation 1
    ]);

    const [topChampionsByRole, setTopChampionsByRole] = useState({});
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [bestCombinations, setBestCombinations] = useState([]);

    const blueChampionsOrder = [
      "BB1",
      "BB2",
      "BB3",
      "BP1",
      "BP2",
      "BP3",
      "BB4",
      "BB5",
      "BP4",
      "BP5",
    ];
    const redChampionsOrder = [
      "RB1",
      "RB2",
      "RB3",
      "RP1",
      "RP2",
      "RP3",
      "RB4",
      "RB5",
      "RP4",
      "RP5",
    ];

    const teamSideOptions = [
        { value: "any", label: 'Any' }, // Change the value to "any"
        { value: "team1", label: 'Blue' },
        { value: "team2", label: 'Red' },
      ];

      const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
      const [selectedFilterChampion, setSelectedFilterChampion] = useState(Array(4).fill());
      const [selectedFilterRole, setSelectedFilterRole] = useState(Array(4).fill({ value: 'any', label: 'Any' }));
      const [selectedFilterType, setSelectedFilterType] = useState(Array(4).fill('any'));
      const [selectedFilterRotation, setSelectedFilterRotation] = useState(Array(4).fill('any'));

      useEffect(() => {
        const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
        const fetchData = async () => {
            try {
                const [officialsResponse, scrimsResponse] = await Promise.all([
                    axios.get(`https://sanchidataportal.com/api/parsedOfficialGames/?start=${start}&end=${end}`),
                    axios.get(`https://sanchidataportal.com/api/parsedscrims/?team=${localStorage.getItem("team")}`)
                ]);

                const officials = officialsResponse.data.map(match => ({ ...match, type: "official" }));
                const scrims = scrimsResponse.data.map(match => ({ ...match, leagueName: "SCRIMS", type: "scrim" }));

                const combinedData = [...officials, ...scrims].sort((a, b) => new Date(b.date) - new Date(a.date));
                setMatchData(combinedData);

                const uniqueLeagues = [...new Set(combinedData.map((match) => match.leagueName))];
                const uniquePatches = [...new Set(combinedData.map((match) => match.patch))]
                .filter(patch => patch !== null)
                .sort((a, b) => {
                  const partsA = a.split('.').map(Number);
                  const partsB = b.split('.').map(Number);

                  if (partsA[0] !== partsB[0]) {
                    return partsA[0] - partsB[0];
                  }
                  return partsA[1] - partsB[1];
                });

                const uniqueTeams = getUniqueTeams(combinedData);
                const filteredLeagues = uniqueLeagues.filter((league) => league !== null);

                const formattedLeagueOptions = filteredLeagues.sort().map((league) => ({
                    value: league,
                    label: league,
                }));

                const formattedPatchOptions = uniquePatches.map((patch) => ({
                    value: patch,
                    label: patch,
                }));


                const formattedTeamOptions = uniqueTeams.map((team) => ({
                    value: team,
                    label: team,
                }));
                const formattedChampionOptions = Object.entries(championData).map(([id, champion]) => ({
                    value: id,
                    label: champion,
                })).sort((a, b) => a.label.localeCompare(b.label));

                setLeagueOption(formattedLeagueOptions);
                setPatchOption(formattedPatchOptions);
                setTeamOption(formattedTeamOptions);
                setChampionOption(formattedChampionOptions);
                setSelectedTeamSide(["any"]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentSeasonTime]);

    const getUniqueTeams = (matchData) => {
      const teamsSet = new Set();
      matchData.forEach((match) => {
        teamsSet.add(match.team1);
        teamsSet.add(match.team2);
      });
      return [...teamsSet];
    };

    useEffect(() => {

      const filteredMatches = matchData.filter((match) => {
        const isLeagueMatch = filterLeague.length === 0 || filterLeague.includes(match.leagueName);
        const isPatchMatch = filterPatch.length === 0 || filterPatch.includes(match.patch);
        const isTeamMatch = filterTeam.length === 0 || (filterTeam.includes(match.team1) && filterTeamSide[0] !== "team2") || (filterTeam.includes(match.team2) && filterTeamSide[0] !== "team1");
        const isGood = match.RP1 !== null;

        const matchChampions = [
            match.BP1, match.BP2, match.BP3, match.BP4, match.BP5, match.RP1, match.RP2, match.RP3, match.RP4, match.RP5
        ].filter(Boolean);

        const isChampionMatch =
            filterChampion.length === 0 ||
            filterChampion.includes('any') ||
            filterChampion.every(championId =>
                matchChampions.includes(Number(championId)) // Check if all selected champions are present in the match
            );

        const isAdditionalFilterMatch = selectedFilterChampion.every((champion, index) => {
            if (!champion || champion.value === 'any') return true;
            const role = selectedFilterRole[index];
            const type = selectedFilterType[index];
            const rotation = selectedFilterRotation[index];

            const championId = Number(champion.value);
            const championIndex = matchChampions.indexOf(championId);

            const side = championIndex < 5 ? 'B' : 'R';
            const position = championIndex < 5 ? championIndex + 1 : championIndex - 4;

            const roleKey = `${side}${position}R`;
            const typeKey = `${side}${position}BC`;

            const isRoleMatch = role.value === 'any' || String(match[roleKey]) === String(role.value);
            const isTypeMatch = type === 'any' || match[typeKey] === type[0].toUpperCase();
            let isRotationMatch = false;
            if (rotation === 'any') {
                isRotationMatch = true;
                if (championIndex === -1) return false;
            } else if (rotation === 'R1-R2') {
                isRotationMatch = (side === 'R' && position <= 2);
            } else if (rotation === 'R3') {
                isRotationMatch = (side === 'R' && position === 3);
            } else if (rotation === 'R4') {
                isRotationMatch = (side === 'R' && position === 4);
            } else if (rotation === 'R5') {
                isRotationMatch = (side === 'R' && position === 5);
            } else if (rotation === 'B1') {
                isRotationMatch = (side === 'B' && position === 1);
            } else if (rotation === 'B2-B3') {
                isRotationMatch = (side === 'B' && (position === 2 || position === 3));
            } else if (rotation === 'B4-B5') {
                isRotationMatch = (side === 'B' && (position === 4 || position === 5));
            } else if (rotation === 'BB1-BB3') {
              isRotationMatch = (Number(champion.value) === match[`BB1`] || Number(champion.value) === match[`BB2`] || Number(champion.value) === match[`BB3`]);
            } else if (rotation === 'BB4-BB5') {
              isRotationMatch = (Number(champion.value) === match[`BB4`] || Number(champion.value) === match[`BB5`]);
            } else if (rotation === 'RB4-RB5') {
              isRotationMatch = (Number(champion.value) === match[`RB4`] || Number(champion.value) === match[`RB5`]);
            } else if (rotation === 'RB1-RB3') {
              isRotationMatch = (Number(champion.value) === match[`RB1`] || Number(champion.value) === match[`RB2`] || Number(champion.value) === match[`RB3`]);
            }
            return isRoleMatch && isTypeMatch && isRotationMatch;
        });

        return isPatchMatch && isTeamMatch && isChampionMatch && isGood && isLeagueMatch && isAdditionalFilterMatch;
    });
    setFilteredMatchData(filteredMatches)
    setChampionOccurrences(countChampionOccurrences(filteredMatches));
    const totalMatches = filteredMatches.length;
    const totalPages = Math.ceil(totalMatches / PAGE_SIZE);
    setTotalPages(totalPages);

    setCurrentPage((prevCurrentPage) => {
      const lastValidPage = Math.min(prevCurrentPage, totalPages);
      return lastValidPage >= 1 ? lastValidPage : 1;
    });

    const uniquePatchesForLeague = [...new Set(filteredMatches.map((match) => match.patch))]
    .filter(patch => patch !== null)
    .sort((a, b) => {
      const partsA = a.split('.').map(Number);
      const partsB = b.split('.').map(Number);

      if (partsA[0] !== partsB[0]) {
        return partsA[0] - partsB[0];
      }
      return partsA[1] - partsB[1];
    });
    const uniqueTeamsForLeague = getUniqueTeams(filteredMatches);

    const formattedPatchOptions = uniquePatchesForLeague.map((patch) => ({
      value: patch,
      label: patch,
    }));

    const formattedTeamOptions = uniqueTeamsForLeague
    .sort((a, b) => a.localeCompare(b)) // Sort the team names alphabetically
    .map((team) => ({
      value: team,
      label: team,
    }));
    setPatchOption(formattedPatchOptions);
    setTeamOption(formattedTeamOptions);

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, totalMatches);
    const matchesForCurrentPage = filteredMatches.slice(startIndex, endIndex);
    setMatchesForCurrentPage(matchesForCurrentPage);
    calculateMostPickedRoles(filteredMatches);
    calculateMostPickedChampions(filteredMatches);
    calculateBlindCounterRoles(filteredMatches)
    setBestCombinations(calculateBestCombinations(filteredMatches, selectedRoles));

}, [selectedRoles, currentPage, matchData, filterPatch, filterTeam, filterChampion, filterLeague, filterTeamSide, selectedFilterChampion, selectedFilterRole, selectedFilterType, selectedFilterRotation, PAGE_SIZE]);

    function countChampionOccurrences(matchData) {
      const championOccurrences = {};
      const wins = {};
      Object.keys(championData).forEach(championId => {
        championOccurrences[championId] = { bans: 0, picks: 0 };
        wins[championId] = 0;
      });

      matchData.forEach(match => {
        for (let i = 1; i <= 5; i++) {
          const blueBanKey = `BB${i}`;
          const redBanKey = `RB${i}`;
          const bluePickKey = `BP${i}`;
          const redPickKey = `RP${i}`;

          const blueBanChampionId = match[blueBanKey];
          const redBanChampionId = match[redBanKey];
          const bluePickChampionId = match[bluePickKey];
          const redPickChampionId = match[redPickKey];
          if (blueBanChampionId) {
            if (championOccurrences[blueBanChampionId]) {
              championOccurrences[blueBanChampionId].bans++;
            } else {
              championOccurrences[blueBanChampionId] = { bans: 1, picks: 0 };
            }
          }

          if (redBanChampionId) {
            if (championOccurrences[redBanChampionId]) {
              championOccurrences[redBanChampionId].bans++;
            } else {
              championOccurrences[redBanChampionId] = { bans: 1, picks: 0 };
            }
          }

          if (bluePickChampionId) {
            if (championOccurrences[bluePickChampionId]) {
              championOccurrences[bluePickChampionId].picks++;
              if (match.winner === 100) {
                wins[bluePickChampionId]++;
              }
            } else {
              championOccurrences[bluePickChampionId] = { bans: 0, picks: 1 };
            }
          }

          if (redPickChampionId) {
            if (championOccurrences[redPickChampionId]) {
              championOccurrences[redPickChampionId].picks++;
              if (match.winner === 200) {
                wins[redPickChampionId]++;
              }
            } else {
              championOccurrences[redPickChampionId] = { bans: 0, picks: 1 };
            }
          }
        }
      });

      Object.keys(championOccurrences).forEach(championId => {
        const picks = championOccurrences[championId]?.picks || 0;
        const bans = championOccurrences[championId]?.bans || 0;
        const totalGames = picks + bans;
        let winRate;
        if (picks === 0) {
          winRate = 0;
        } else {
          winRate = (wins[championId] / picks) * 100;
        }
        const presence = (totalGames / matchData.length) * 100;
        championOccurrences[championId].winRate = winRate;
        championOccurrences[championId].presence = presence;
      });

      return championOccurrences;
    }

    const handleSort = (column) => {
      if (column === sortColumn) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortColumn(column);
        setSortDirection('asc');
      }
    };

    const handleRoleChange = (roleId) => {
      setSelectedRoles(prevRoles => {
          if (prevRoles.includes(roleId)) {
              return prevRoles.filter(role => role !== roleId);
          } else {
              return [...prevRoles, roleId];
          }
      });
    };

    const getColorFromValueBlue = (value) => {
      const lightness = 100 - (value / 2); // Calculate lightness between 100 and 50
      return `hsl(213, 100%, ${lightness}%)`; // Convert to HSL color
    };

    const getColorFromValueRed = (value) => {
      const lightness = 100 - (value / 2); // Calculate lightness between 100 and 50
      return `hsl(360, 100%, ${lightness}%)`; // Convert to HSL color
    };

    const renderPaginationItems = () => {
      const paginationItems = [];
      const ellipsis = <Pagination.Ellipsis />;

      paginationItems.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
      );

      if (totalPages <= 5) {
        // If total pages are less than or equal to 5, display all pages
        for (let i = 1; i <= totalPages; i++) {
          paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
              {i}
            </Pagination.Item>
          );
        }
      } else {
        // Display the first 2 pages and the last 2 pages
        for (let i = 1; i <= 2; i++) {
          paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
              {i}
            </Pagination.Item>
          );
        }

        // Display the current page and the last page, and ellipsis in between if needed
        if (currentPage - 2 > 2) {
          paginationItems.push(ellipsis);
        }
        for (let i = Math.max(currentPage - 2, 3); i <= Math.min(currentPage + 2, totalPages - 2); i++) {
          paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
              {i}
            </Pagination.Item>
          );
        }
        if (currentPage + 2 < totalPages - 1) {
          paginationItems.push(ellipsis);
        }

        for (let i = totalPages - 1; i <= totalPages; i++) {
          paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
              {i}
            </Pagination.Item>
          );
        }
      }

      // Always display the next page and last page if needed
      paginationItems.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
      );

      return paginationItems;
    };

    const calculateBestCombinations = (matches, selectedRoles) => {
      if (selectedRoles.length === 0) return [];

      const roleCombinations = {};

      matches.forEach(match => {
        const blueRoles = selectedRoles.map(role => {
          for (let i = 1; i <= 5; i++) {
            const roleKey = `B${i}R`;
            const pickKey = `BP${i}`;
            if (match[roleKey] === role) {
              return {
                role,
                champion: match[pickKey] ? match[pickKey] : null,
              };
            }
          }
          return { role, champion: null };
        }).filter(role => role.champion);

        const redRoles = selectedRoles.map(role => {
          for (let i = 1; i <= 5; i++) {
            const roleKey = `R${i}R`;
            const pickKey = `RP${i}`;
            if (match[roleKey] === role) {
              return {
                role,
                champion: match[pickKey] ? match[pickKey] : null,
              };
            }
          }
          return { role, champion: null };
        }).filter(role => role.champion);

        const blueCombinationKey = blueRoles.map(r => r.champion).join('-');
        const redCombinationKey = redRoles.map(r => r.champion).join('-');

        if (roleCombinations[blueCombinationKey]) {
          roleCombinations[blueCombinationKey].count++;
          roleCombinations[blueCombinationKey].wins += (match.winner === 100 ? 1 : 0);
        } else {
          roleCombinations[blueCombinationKey] = { roles: blueRoles, count: 1, wins: match.winner === 100 ? 1 : 0 };
        }

        if (roleCombinations[redCombinationKey]) {
          roleCombinations[redCombinationKey].count++;
          roleCombinations[redCombinationKey].wins += (match.winner === 200 ? 1 : 0);
        } else {
          roleCombinations[redCombinationKey] = { roles: redRoles, count: 1, wins: match.winner === 200 ? 1 : 0 };
        }
      });

      const sortedCombinations = Object.values(roleCombinations).filter(combo => combo.count > 1).sort((a, b) => b.count - a.count);
      return sortedCombinations.map(combo => ({
        ...combo,
        winRate: (combo.wins / combo.count) * 100
      })); // Return sorted combinations with win rates
    };

    const handleLeagueChange = (selectedOptions) => {
        setMatchesForCurrentPage([]);
        const leagueValues = selectedOptions.map((option) => option.value);
        setFilterLeague(leagueValues);
        setSelectedLeague(selectedOptions);
      };

      const handleTeamChange = (selectedOptions) => {
        setMatchesForCurrentPage([]);
        const leagueValues = selectedOptions.map((option) => option.value);
        setFilterTeam(leagueValues);
        setSelectedTeam(selectedOptions);
      };

      const handleTeamSideChange = (selectedOption) => {
        setMatchesForCurrentPage([]);
        setFilterTeamSide([selectedOption]); // Ensure that the value is wrapped in an array
        setSelectedTeamSide(selectedOption);
      };

      const handlePatchChange = (selectedOptions) => {
        setMatchesForCurrentPage([]);
        const leagueValues = selectedOptions.map((option) => option.value);
        setFilterPatch(leagueValues);
        setSelectedPatch(selectedOptions);
      };

      const handleChampionChange = (selectedOptions) => {
        setMatchesForCurrentPage([]);
        const championValues = selectedOptions.map((option) => option.value);
        const championIds = championValues.map(Number);
        setFilterChampion(championIds);
        setSelectedChampion(selectedOptions);
      };

      const handlePageChange = (pageNumber) => {
        if (pageNumber === currentPage) {
          return;
        }
        setMatchesForCurrentPage([]);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
          setCurrentPage(pageNumber);
        }
      };

      const calculateMostPickedRoles = (matches) => {
        const blueRoles2 = [...Array(3)].map(() => Array(5).fill(0));
        const redRoles2 = [...Array(4)].map(() => Array(5).fill(0));
        var blueCount = 0;
        var redCount = 0;
        matches.forEach((match) => {
          for (let rotaton = 0; rotaton <= 4; rotaton++) {
            const blueRoleKey = `B${rotaton + 1}R`;
            const redRoleKey = `R${rotaton + 1}R`;
            if (match[blueRoleKey] !== null) {
              if ((filterTeamSide[0]==="team1" && filterTeam.includes(match.team1)) || (filterTeam.includes(match.team1) && filterTeamSide[0]==="any") || (filterTeam.length===0 && filterTeamSide[0]==="any") )
              {
                if (rotaton === 1 || rotaton === 2) {
                  blueRoles2[1][match[blueRoleKey]] += 1;
                } else if (rotaton === 3 || rotaton === 4) {
                  blueRoles2[2][match[blueRoleKey]] += 1;
                } else {
                  blueRoles2[0][match[blueRoleKey]] += 1;
                }
                blueCount += 1;
              }
              if ((filterTeamSide[0]==="team2" && filterTeam.includes(match.team2)) || (filterTeam.includes(match.team2) && filterTeamSide[0]==="any") || (filterTeam.length===0 && filterTeamSide[0]==="any") )
              {
              if (rotaton === 0 || rotaton === 1) {
                redRoles2[0][match[redRoleKey]] += 1;
              } else if (rotaton === 2) {
                redRoles2[1][match[redRoleKey]] += 1;
              } else if (rotaton === 3) {
                redRoles2[2][match[redRoleKey]] += 1;
              } else {
                redRoles2[3][match[redRoleKey]]+= 1;
              }
              redCount += 1;
            }
            }
          }
        });
        const blueRoles3 = blueRoles2.map(innerArray => {
          return innerArray.map(value => ((value / (blueCount/5)) * 100).toFixed(1));
        });
        const redRoles3 = redRoles2.map(innerArray => {
          return innerArray.map(value => ((value / (redCount/5)) * 100).toFixed(1));
        });
        setBlueRoles(blueRoles3);
        setRedRoles(redRoles3);
      };

      const calculateBlindCounterRoles = (matches) => {
        const blueRoles2 = [...Array(2)].map(() => Array(5).fill(0));
        const redRoles2 = [...Array(2)].map(() => Array(5).fill(0));
        var blueCount = 0;
        var redCount = 0;
        matches.forEach((match) => {
          for (let rotaton = 0; rotaton <= 4; rotaton++) {
            const blueRoleKey = `B${rotaton + 1}BC`;
            const redRoleKey = `R${rotaton + 1}BC`;
            const blueRoleKey1 = `B${rotaton + 1}R`;
            const redRoleKey1 = `R${rotaton + 1}R`;
            if (match[blueRoleKey] !== null) {
              if ((filterTeamSide[0]==="team1" && filterTeam.includes(match.team1)) || (filterTeam.includes(match.team1) && filterTeamSide[0]==="any") || (filterTeam.length===0 && filterTeamSide[0]==="any") )
              {
                if (match[blueRoleKey] === "B") {
                  blueRoles2[0][match[blueRoleKey1]] += 1;
                } else {
                  blueRoles2[1][match[blueRoleKey1]] += 1;
                }
                blueCount += 1;
              }
              if ((filterTeamSide[0]==="team2" && filterTeam.includes(match.team2)) || (filterTeam.includes(match.team2) && filterTeamSide[0]==="any") || (filterTeam.length===0 && filterTeamSide[0]==="any") )
              {
              if (match[redRoleKey] === "B") {
                redRoles2[0][match[redRoleKey1]] += 1;
              } else {
                redRoles2[1][match[redRoleKey1]]+= 1;
              }
              redCount += 1;
            }
            }
          }
        });
        const blueRoles3 = blueRoles2.map(innerArray => {
          return innerArray.map(value => ((value / (blueCount/5)) * 100).toFixed(1));
        });
        const redRoles3 = redRoles2.map(innerArray => {
          return innerArray.map(value => ((value / (redCount/5)) * 100).toFixed(1));
        });
        setBlueBlindCounter(blueRoles3);
        setRedBlindCounter(redRoles3);
      };

      const calculateMostPickedChampions = (matches) => {
        const B1 = {};
        const B2B3 = {};
        const B4B5 = {};
        const R1R2 = {};
        const R3 = {};
        const R4 = {};
        const R5 = {};
        const BB1B = {};
        const BB2B = {};
        const BB1A = {};
        const BB2A = {};
        const RB1B = {};
        const RB2B = {};
        const RB1A = {};
        const RB2A = {};

        matches.forEach((match) => {
          if ((filterTeamSide[0] === "team1" && filterTeam.includes(match.team1)) ||
              (filterTeam.includes(match.team1) && filterTeamSide[0] === "any") ||
              (filterTeam.length === 0 && filterTeamSide[0] === "any")) {

            if (match.BP1) B1[match.BP1] = (B1[match.BP1] || 0) + 1;
            if (match.BP2) B2B3[match.BP2] = (B2B3[match.BP2] || 0) + 1;
            if (match.BP3) B2B3[match.BP3] = (B2B3[match.BP3] || 0) + 1;
            if (match.BP4) B4B5[match.BP4] = (B4B5[match.BP4] || 0) + 1;
            if (match.BP5) B4B5[match.BP5] = (B4B5[match.BP5] || 0) + 1;

            if (match.BB1) BB1B[match.BB1] = (BB1B[match.BB1] || 0) + 1;
            if (match.BB2) BB1B[match.BB2] = (BB1B[match.BB2] || 0) + 1;
            if (match.BB3) BB1B[match.BB3] = (BB1B[match.BB3] || 0) + 1;
            if (match.BB4) BB2B[match.BB4] = (BB2B[match.BB4] || 0) + 1;
            if (match.BB5) BB2B[match.BB5] = (BB2B[match.BB5] || 0) + 1;

            if (match.RB1) RB1A[match.RB1] = (RB1A[match.RB1] || 0) + 1;
            if (match.RB2) RB1A[match.RB2] = (RB1A[match.RB2] || 0) + 1;
            if (match.RB3) RB1A[match.RB3] = (RB1A[match.RB3] || 0) + 1;
            if (match.RB4) RB2A[match.RB4] = (RB2A[match.RB4] || 0) + 1;
            if (match.RB5) RB2A[match.RB5] = (RB2A[match.RB5] || 0) + 1;
          }

          if ((filterTeamSide[0] === "team2" && filterTeam.includes(match.team2)) ||
              (filterTeam.includes(match.team2) && filterTeamSide[0] === "any") ||
              (filterTeam.length === 0 && filterTeamSide[0] === "any")) {

            if (match.RP1) R1R2[match.RP1] = (R1R2[match.RP1] || 0) + 1;
            if (match.RP2) R1R2[match.RP2] = (R1R2[match.RP2] || 0) + 1;
            if (match.RP3) R3[match.RP3] = (R3[match.RP3] || 0) + 1;
            if (match.RP4) R4[match.RP4] = (R4[match.RP4] || 0) + 1;
            if (match.RP5) R5[match.RP5] = (R5[match.RP5] || 0) + 1;

            if (match.RB1) RB1B[match.RB1] = (RB1B[match.RB1] || 0) + 1;
            if (match.RB2) RB1B[match.RB2] = (RB1B[match.RB2] || 0) + 1;
            if (match.RB3) RB1B[match.RB3] = (RB1B[match.RB3] || 0) + 1;
            if (match.RB4) RB2B[match.RB4] = (RB2B[match.RB4] || 0) + 1;
            if (match.RB5) RB2B[match.RB5] = (RB2B[match.RB5] || 0) + 1;

            if (match.BB1) BB1A[match.BB1] = (BB1A[match.BB1] || 0) + 1;
            if (match.BB2) BB1A[match.BB2] = (BB1A[match.BB2] || 0) + 1;
            if (match.BB3) BB1A[match.BB3] = (BB1A[match.BB3] || 0) + 1;
            if (match.BB4) BB2A[match.BB4] = (BB2A[match.BB4] || 0) + 1;
            if (match.BB5) BB2A[match.BB5] = (BB2A[match.BB5] || 0) + 1;
          }
        });

        const topChampionsByRole1 = {
          B1: getTopChampions(B1, 5),
          B2B3: getTopChampions(B2B3, 10),
          B4B5: getTopChampions(B4B5, 10),
          R1R2: getTopChampions(R1R2, 10),
          R3: getTopChampions(R3, 5),
          R4: getTopChampions(R4, 5),
          R5: getTopChampions(R5, 5),
          BB1B: getTopChampions(BB1B, 5),
          BB2B: getTopChampions(BB2B, 5),
          BB1A: getTopChampions(BB1A, 5),
          BB2A: getTopChampions(BB2A, 5),
          RB1B: getTopChampions(RB1B, 5),
          RB2B: getTopChampions(RB2B, 5),
          RB1A: getTopChampions(RB1A, 5),
          RB2A: getTopChampions(RB2A, 5),
        };

        setTopChampionsByRole(topChampionsByRole1);
      };

      const getTopChampions = (championCounts, max) => {
        const sortedChampions = Object.entries(championCounts)
          .filter(([championId]) => championId !== null && championId !== undefined)
          .sort((a, b) => b[1] - a[1]);

        while (sortedChampions.length < max) {
          sortedChampions.push([null, null]);
        }
        return sortedChampions.slice(0, max).map(([championId, count]) => ({ championId, count }));
      };

      const handleTeamClick = (teamName) => {
        window.location.href = `/team/${teamName}`;
      };

      const handleMatchClick = (teamName) => {
        window.location.href = `/match/${teamName}`;
      };

      const getRedGreenCellno0 = (value, minValue, maxValue) => {
        if (value === (minValue + maxValue) / 2) {
          return `hsl(0, 0%, 100%)`;
        } else {
          const midpoint = (minValue + maxValue) / 2;
          const normalizedValue =
            value > midpoint
              ? (value - midpoint) / (maxValue - midpoint)
              : (-value + midpoint) / (minValue + midpoint);
          const hue = value > midpoint ? 120 : 0;
          const lightness = 100 - 50 * normalizedValue;
          return `hsl(${hue}, 100%, ${lightness}%)`;
        }
      };

      const getGreenCellColor = (value, maxValue) => {
        const normalizedValue = value / maxValue;
        const hue = 128;
        const saturation = 100;
        const minLightness = 100;
        const maxLightness = 50;
        const lightness = minLightness - normalizedValue * (minLightness - maxLightness);
        const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        return color;
      };

    return (
      <div className='wrapper'>

        <div className='filters'>
          League
          <div className='short-chooser'>
            <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
          </div>
          Patch
          <div className='short-chooser'>
            <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
          </div>
          Team
          <div className='short-chooser'>
            <CustomSelect options={teamOption} selectedOption={selectedTeam} onOptionChange={handleTeamChange} />
          </div>
          Blue/Any/Red
          <div>
            <select
              id="side-select"
              value={selectedTeamSide}
              onChange={(e) => handleTeamSideChange(e.target.value)}
            >
              {teamSideOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          Champion
          <div className='short-chooser'>
            <CustomSelect options={[{ value: 'any', label: 'Any' }, ...championOption]} selectedOption={selectedChampion} onOptionChange={handleChampionChange} />
          </div>
          <div className='filter-toggle'>
            <button onClick={() => setIsFilterMenuVisible(!isFilterMenuVisible)}>
              {isFilterMenuVisible ? "Hide Filters" : "Show Filters"}
            </button>
          </div>
        </div>
        {isFilterMenuVisible && (
          <div className='new-filter' style={{ display: "flex", flexWrap: 'wrap', justifyContent: 'center', marginBottom: "20px",  backgroundColor: "white", borderRadius: "10px" }}>
            {[...Array(4).keys()].map((_, i) => (
              <div key={i} className='filter-row' style={{ display: "flex", width: "50%", justifyContent: "center" }}>
                <div>
                  <label>Champion:</label>
                  <select
                    value={selectedFilterChampion[i] ? selectedFilterChampion[i].value : 'any'}
                    onChange={(e) => {
                      const selected = championOption.find(option => option.value === e.target.value) || { value: 'any', label: 'Any' };
                      const newFilterChampion = [...selectedFilterChampion];
                      newFilterChampion[i] = selected;
                      setSelectedFilterChampion(newFilterChampion);
                    }}
                  >
                    <option value="any">Any</option>
                    {championOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Role:</label>
                  <select
                    value={selectedFilterRole[i] ? selectedFilterRole[i].value : 'any'}
                    onChange={(e) => {
                      const selected = { value: e.target.value, label: e.target.value };
                      const newFilterRole = [...selectedFilterRole];
                      newFilterRole[i] = selected;
                      setSelectedFilterRole(newFilterRole);
                    }}
                  >
                    <option value="any">Any</option>
                    <option value="0">TOP</option>
                    <option value="1">JGL</option>
                    <option value="2">MID</option>
                    <option value="3">ADC</option>
                    <option value="4">SUP</option>
                  </select>
                </div>
                <div>
                  <label>Type:</label>
                  <select
                    value={selectedFilterType[i]}
                    onChange={(e) => {
                      const newFilterType = [...selectedFilterType];
                      newFilterType[i] = e.target.value;
                      setSelectedFilterType(newFilterType);
                    }}
                  >
                    <option value="any">Any</option>
                    <option value="blind">Blind</option>
                    <option value="counter">Counter</option>
                  </select>
                </div>
                <div>
                  <label>Rotation:</label>
                  <select
                    value={selectedFilterRotation[i]}
                    onChange={(e) => {
                      const newFilterRotation = [...selectedFilterRotation];
                      newFilterRotation[i] = e.target.value;
                      setSelectedFilterRotation(newFilterRotation);
                    }}
                  >
                    <option value="any">Any</option>
                    <option value="R1-R2">R1-R2</option>
                    <option value="R3">R3</option>
                    <option value="R4">R4</option>
                    <option value="R5">R5</option>
                    <option value="B1">B1</option>
                    <option value="B2-B3">B2-B3</option>
                    <option value="B4-B5">B4-B5</option>
                    <option value="BB1-BB3">BB1-BB3</option>
                    <option value="BB4-BB5">BB4-BB5</option>
                    <option value="RB1-RB3">RB1-RB3</option>
                    <option value="RB4-RB5">RB4-RB5</option>
                  </select>
                </div>
              </div>
            ))}
          </div>
        )}


        <div className='draft-wrapper'>
          {matchData ? (
            <>
            {matchesForCurrentPage.map((match, matchIndex) => (
              <div key={matchIndex} className='match-draft-container-2'>
                <table>
                  <thead style={{ textAlign: 'center', backgroundColor: 'white' }}>
                    <tr>
                      <th className="default-cell-img" onClick={() => handleMatchClick(match.gameId)} style={{backgroundColor: match.winner === 100 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 100 ? 1 : 0}</th>
                      <th className="teamName" onClick={() => handleTeamClick(match.team1)} style={{ backgroundColor: filterTeam.includes(match.team1) ? "yellow" :  "rgb(217, 217, 217)" }} > <img src={TeamIcons[match.team1]} alt='ni ma ikonki' className="small-image"/>{match.team1}</th>
                      <th className='default-doublecell-img' onClick={() => handleMatchClick(match.gameId)} colSpan={2}>{match.patch}</th>
                      <th className="teamName" onClick={() => handleTeamClick(match.team2)} style={{ backgroundColor: filterTeam.includes(match.team2) ? "yellow" :  "rgb(217, 217, 217)" }}><img src={TeamIcons[match.team2]} alt='ni ma ikonki' className="small-image"/>{match.team2}</th>
                      <th className="default-cell-img" onClick={() => handleMatchClick(match.gameId)} style={{backgroundColor: match.winner === 200 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 200 ? 1 : 0}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(10)].map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell-img" : "default-cell-img"}>
                          <img
                            src={championPhotos[championData[match[blueChampionsOrder[rowIndex]]]]}
                            alt={""}
                            className="small-image"
                          />
                        </td>
                        <td style={{borderBottom: rowIndex === 3 ? '2px solid black' : 'none'}} colSpan={rowIndex === 0 || rowIndex === 1 || rowIndex === 2 || rowIndex === 6 || rowIndex === 7 ? 2 : 1} className={(filterChampion.includes(match[blueChampionsOrder[rowIndex]]) || selectedFilterChampion.some(champ => champ && champ.value && Number(champ.value) === match[blueChampionsOrder[rowIndex]]) ? "yellow-cell" : (rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell" : "default-cell"))}>
                          {championData[match[blueChampionsOrder[rowIndex]]]}
                        </td>
                        {rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? (
                          <>
                          {rowIndex === 8 || rowIndex === 9 ? (
                            <>
                            <td className={"blue-cell-img"}>
                              <img src={roleIcon[match['B'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                            </td>
                            <td className={"red-cell-img"}>
                              <img src={roleIcon[match['R'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                            </td>
                            </>
                          ):(
                            <>
                              <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "blue-cell-img" : "default-cell-img"}>
                                <img src={roleIcon[match['B'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                              </td>
                              <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "red-cell-img" : "default-cell-img"}>
                                <img src={roleIcon[match['R'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                              </td>
                            </>
                          )
                        }
                          <td colSpan={1} style={{ borderBottom: rowIndex === 4 || rowIndex === 8 ? '2px solid black' : 'none' }}  className={ (filterChampion.includes(match[redChampionsOrder[rowIndex]]) || selectedFilterChampion.some(champ => champ && champ.value && Number(champ.value) === match[redChampionsOrder[rowIndex]]) ? "yellow-cell" : rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell")}>
                            {championData[match[redChampionsOrder[rowIndex]]]}
                          </td>
                          </>
                        ):(
                          <>
                          <td  colSpan={2} className={ (filterChampion.includes(match[redChampionsOrder[rowIndex]]) || selectedFilterChampion.some(champ => champ && champ.value && Number(champ.value) === match[redChampionsOrder[rowIndex]]) ? "yellow-cell" : rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell")}>
                            {championData[match[redChampionsOrder[rowIndex]]]}
                          </td>
                          </>
                        )}
                        <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell-img" : "default-cell-img"}>
                          <img
                            src={championPhotos[championData[match[redChampionsOrder[rowIndex]]]]}
                            alt={""}
                            className="small-image"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}

            </>
            ) : (
              <div className='empty-data-message'>
                No matches found for the selected filters.
              </div>
            )}
            <div className='main-pagination'>
              <Pagination  style={{ display: 'flex', justifyContent: 'center' }}>{renderPaginationItems()}</Pagination>
            </div>
        </div>
        {blueRoles ? (
      <>
        <div className='drafts-stats'>
          <div>
            <br></br>
            <table style={{backgroundColor: "white"}}>
              <thead>
                <tr>
                  <th>Role</th>
                  <th>B1</th>
                  <th>B2-B3</th>
                  <th>B4-B5</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 5 }, (_, rotationIndex) => (
                  <tr key={rotationIndex}>
                    <td>
                      <img src={roleIcon[rotationIndex]} alt="s" className="small-image" />
                    </td>
                    <td style={{ backgroundColor: getColorFromValueBlue(blueRoles[0][rotationIndex]) }}>
                      {blueRoles[0][rotationIndex]}%
                    </td>
                    <td style={{ backgroundColor: getColorFromValueBlue(blueRoles[1][rotationIndex]) }}>
                      {blueRoles[1][rotationIndex]}%
                    </td>
                    <td style={{ backgroundColor: getColorFromValueBlue(blueRoles[2][rotationIndex]) }}>
                      {blueRoles[2][rotationIndex]}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
          <div>
            <br></br>
            <table style={{backgroundColor: "white"}}>
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Blind</th>
                  <th>Counter</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 5 }, (_, rotationIndex) => (
                  <tr key={rotationIndex}>
                    <td>
                      <img src={roleIcon[rotationIndex]} alt="s" className="small-image" />
                    </td>
                    <td style={{ backgroundColor: getColorFromValueBlue(blueBlindCounter[0][rotationIndex]) }}>
                      {blueBlindCounter[0][rotationIndex]}%
                    </td>
                    <td style={{ backgroundColor: getColorFromValueBlue(blueBlindCounter[1][rotationIndex]) }}>
                      {blueBlindCounter[1][rotationIndex]}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
          <div>
            <br></br>
            <table style={{backgroundColor: "white"}}>
              <thead>
                <tr>
                  <th>Role</th>
                    <th>R1-R2</th>
                    <th>R3</th>
                    <th>R4</th>
                    <th>R5</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 5 }, (_, rotationIndex) => (
                  <tr key={rotationIndex}>
                    <td>
                      <img src={roleIcon[rotationIndex]} alt="s" className="small-image" />
                    </td>
                    <td style={{ backgroundColor: getColorFromValueRed(redRoles[0][rotationIndex]) }}>
                      {redRoles[0][rotationIndex]}%
                    </td>
                    <td style={{ backgroundColor: getColorFromValueRed(redRoles[1][rotationIndex]) }}>
                      {redRoles[1][rotationIndex]}%
                    </td>
                    <td style={{ backgroundColor: getColorFromValueRed(redRoles[2][rotationIndex]) }}>
                      {redRoles[2][rotationIndex]}%
                    </td>
                    <td style={{ backgroundColor: getColorFromValueRed(redRoles[3][rotationIndex]) }}>
                      {redRoles[3][rotationIndex]}%
                    </td>
                       {/* Assuming the role name is the first item in the rotation */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <br></br>
            <table style={{backgroundColor: "white"}}>
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Blind</th>
                  <th>Counter</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 5 }, (_, rotationIndex) => (
                  <tr key={rotationIndex}>
                    <td>
                      <img src={roleIcon[rotationIndex]} alt="s" className="small-image" />
                    </td>
                    <td style={{ backgroundColor: getColorFromValueRed(redBlindCounter[0][rotationIndex]) }}>
                      {redBlindCounter[0][rotationIndex]}%
                    </td>
                    <td style={{ backgroundColor: getColorFromValueRed(redBlindCounter[1][rotationIndex]) }}>
                      {redBlindCounter[1][rotationIndex]}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </>
        ) : (
          <>
        <div className='empty-data-message'>
          No matches found for the selected filters.
        </div>
          </>
        )}
        <div className='champion-stats'>
          {matchData.length !== 0 && topChampionsByRole["B1"].length !== 0 ? (
            <>
              <div className='order-table'>
                <table style={{backgroundColor: "white"}}>
                  <thead>
                    <tr>
                      <th colSpan={8} style={{textAlign: "center", borderBottom: '3px solid black'}}>Number of picks by rotation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td  style={{borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["B1"][index]["count"]] || "-"}
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["B1"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["B1"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none',  borderRight: '2px solid black' }}> {index === 2 ? "B1" : null} </td>
                        <td>{index === 2 ? "R1 / R2" : null}</td>
                        <td>
                          {championData[topChampionsByRole["R1R2"][index]["championId"]] || "-"}
                        </td>
                        <td  style={{width: "30px" }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["R1R2"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["R1R2"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}

                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td style={{textAlign: "center"}}>
                          {[topChampionsByRole["B2B3"][index]["count"]] || "-"}
                        </td>
                        <td>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["B2B3"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td>
                          {championData[topChampionsByRole["B2B3"][index]["championId"]] || "-"}
                        </td>
                        <td style={{borderRight: '2px solid black' }}> {index === 2 ? "B2 / B3" : null}</td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}> {index === 2 ? "R1 / R2" : null}</td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["R1R2"][index+5]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["R1R2"][index+5]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}>
                          {[topChampionsByRole["R1R2"][index+5]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center"}}>
                          {[topChampionsByRole["B2B3"][index+5]["count"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none'}}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["B2B3"][index+5]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none'}}>
                          {championData[topChampionsByRole["B2B3"][index+5]["championId"]] || "-"}
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none', borderRight: '2px solid black' }}> {index === 2 ? "B2 / B3" : null} </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}> {index === 2 ? "R3" : null} </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["R3"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["R3"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}>
                          {[topChampionsByRole["R3"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td style={{textAlign: "center"}}>
                          {[topChampionsByRole["B4B5"][index]["count"]] || "-"}
                        </td>
                        <td>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["B4B5"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td>
                          {championData[topChampionsByRole["B4B5"][index]["championId"]] || "-"}
                        </td>
                        <td  style={{borderRight: '2px solid black' }}> {index === 2 ? "B4 / B5" : null} </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}> {index === 2 ? "R4" : null} </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["R4"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["R4"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}>
                          {[topChampionsByRole["R4"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}>
                          {[topChampionsByRole["B4B5"][index+5]["count"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["B4B5"][index+5]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["B4B5"][index+5]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', borderRight: '2px solid black' }}> {index === 2 ? "B4 / B5" : null}</td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}> {index === 2 ? "R5" : null} </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["R5"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["R5"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}>
                          {[topChampionsByRole["R5"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </>
          ) : (
              <>
              <div className='empty-data-message'>
              No matches found for the selected filters.
              </div>
            </>
            )}
          <div className='order-table'>

          {matchData.length !== 0 && topChampionsByRole["B1"].length !== 0 ? (
            <>
              <div>
                <table style={{backgroundColor: "white"}}>
                  <thead>
                    <tr>
                      <th colSpan={3} style={{textAlign: "center", borderBottom: '3px solid black'}}>Blue </th>
                      <th colSpan={2} style={{textAlign: "center", borderBottom: '3px solid black'}}>BANS BY TEAM SELECTED</th>
                      <th colSpan={3} style={{textAlign: "center", borderBottom: '3px solid black'}}>Red</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td  style={{borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center", }}>
                          {[topChampionsByRole["BB1B"][index]["count"]] || "-"}
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["BB1B"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["BB1B"][index]["championId"]] || "-"}
                        </td>
                        <td colSpan={2} style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}>  {index === 1 ? "1 rotation" : ""}</td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["RB1B"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["RB1B"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center", }}>
                          {[topChampionsByRole["RB1B"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td  style={{borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["BB2B"][index]["count"]] || "-"}
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["BB2B"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["BB2B"][index]["championId"]] || "-"}
                        </td>
                        <td colSpan={2} style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}>  {index === 2 ? "2 rotation" : ""}</td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["RB2B"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["RB2B"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["RB2B"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </>
          ) : (
              <>
              <div className='empty-data-message'>
              No matches found for the selected filters.
              </div>
            </>
            )}
            <br></br>
          {matchData.length !== 0 && topChampionsByRole["B1"].length !== 0 ? (
            <>
              <div>
                <table style={{backgroundColor: "white"}}>
                  <thead>
                    <tr>
                      <th colSpan={3} style={{textAlign: "center", borderBottom: '3px solid black'}}>Blue </th>
                      <th colSpan={2} style={{textAlign: "center", borderBottom: '3px solid black'}}>BANS AGAINST TEAM SELECTED</th>
                      <th colSpan={3} style={{textAlign: "center", borderBottom: '3px solid black'}}>Red</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td  style={{borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["BB1A"][index]["count"]] || "-"}
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["BB1A"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["BB1A"][index]["championId"]] || "-"}
                        </td>
                        <td colSpan={2} style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}> {index === 2 ? "1 rotation" : ""} </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["RB1A"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["RB1A"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["RB1A"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td  style={{borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["BB2A"][index]["count"]] || "-"}
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px"  }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["BB2A"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td  style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["BB2A"][index]["championId"]] || "-"}
                        </td>
                        <td colSpan={2} style={{ borderBottom: index === 4 ? '2px solid black' : 'none', textAlign: "center" }}> {index === 2 ? "2 rotation" : ""}</td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none' }}>
                          {championData[topChampionsByRole["RB2A"][index]["championId"]] || "-"}
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px"  }}>
                          <img className="small-image" src={championPhotos[championData[topChampionsByRole["RB2A"][index]["championId"]]]} alt=' '/>
                        </td>
                        <td style={{ borderBottom: index === 4 ? '2px solid black' : 'none', width: "30px", textAlign: "center" }}>
                          {[topChampionsByRole["RB2A"][index]["count"]] || "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </>
          ) : (
              <>
              <div className='empty-data-message'>
              No matches found for the selected filters.
              </div>
            </>
            )}
          </div>
            <div style={{ color: 'white', backgroundColor: 'black', padding: "10px", width: "34%", maxHeight: "800px", overflowY: "scroll" }}>
                {championOccurrences && (
                <>
                <table style={{color: 'black', backgroundColor: 'white'}}>
                  <thead>
                    <tr>
                      <th style={{textAlign: "center"}}colSpan={5}>Champion Occurrences</th>
                    </tr>
                    <tr>
                      <th onClick={() => handleSort('championName')}>Champion</th>
                      <th onClick={() => handleSort('picks')}>Picks</th>
                      <th onClick={() => handleSort('bans')}>Bans</th>
                      <th onClick={() => handleSort('winRate')}>Win Rate</th>
                      <th onClick={() => handleSort('presence')}>Presence</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(championOccurrences)
                      .filter(championId => championId !== '0')
                      .filter(championId => {
                        const presence = championOccurrences[championId].presence;
                        return presence > 0;
                      })
                      .sort((a, b) => {
                        const aValue = championOccurrences[a][sortColumn];
                        const bValue = championOccurrences[b][sortColumn];
                        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
                      })
                      .map(championId => {
                        const { picks, bans, winRate, presence } = championOccurrences[championId];
                        return (
                          <tr key={championId}>
                            <td>
                              <img
                                src={championPhotos[championData[championId]]}
                                alt=''
                                className='small-image'
                              />
                              {championData[championId]}
                            </td>
                            <td>{picks}</td>
                            <td>{bans}</td>
                            <td style={{backgroundColor: getRedGreenCellno0(winRate.toFixed(2), 0, 100) }}>{winRate.toFixed(2)}%</td>
                            <td style={{backgroundColor: getGreenCellColor(presence.toFixed(2), 100) }}>{presence.toFixed(2)}%</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                </>
                )}
            </div>
          </div>
          <div style={{ color: 'black', width: "30%", backgroundColor: 'black', padding: "10px" }}>
            <table style={{ color: 'black', backgroundColor: 'white'}}>
              <thead>
                <tr>
                  {Object.keys(roleIcon).map((key) => {
                    return (
                      <th key={key} style={{ width: `${100 / (Object.keys(roleIcon).length + 2)}%`, textAlign: 'center' }}>
                        <input
                          type="checkbox"
                          checked={selectedRoles.includes(Number(key))}
                          onChange={() => handleRoleChange(Number(key))}
                        />
                        <img className="medium-image" src={roleIcon[key]} alt={`role${key} icon`} />
                      </th>
                    );
                  })}
                  <th style={{ width: `${100 / (Object.keys(roleIcon).length + 2)}%`, textAlign: "center" }}>Pick</th>
                  <th style={{ width: `${100 / (Object.keys(roleIcon).length + 2)}%`, textAlign: "center" }}>WR</th>
                </tr>
              </thead>
              <tbody>
                {bestCombinations.map((combo, index) => (
                  <tr key={index}>
                    {Object.keys(roleIcon).map((key) => (
                      <td key={key} style={{ textAlign: 'center' }}>
                        {combo.roles.some(role => role.role === Number(key)) ? (
                          combo.roles
                            .filter(role => role.role === Number(key))
                            .map(role => (
                              <div key={role.role}>
                                <img
                                  src={championPhotos[championData[role.champion]]}
                                  alt={championData[role.champion]}
                                  className="small-image"
                                />
                              </div>
                            ))
                        ) : (
                          <div style={{ height: '48px' }}></div>
                        )}
                      </td>
                    ))}
                    <td style={{ textAlign: 'center' }}>{combo.count}</td>
                    <td style={{ textAlign: 'center', backgroundColor: getRedGreenCellno0(combo.winRate, 0, 100)  }}>{combo.winRate.toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={{ width: "70%"}}>
              <ChampionRoleStats filteredMatches={filteredMatchData}/>
          </div>
      </div>
    );
};

export default DraftsComponent;
