import React, { useEffect, useState } from 'react';
import { championPhotos } from "./ChampionsIcon";

const ChampionPerformanceComponent = ({playerData}) => {
    const [championDataDictionary, setChampionDataDictionary] = useState({});
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedChampionData, setSortedChampionData] = useState([]);
    const [hideLowGames, setHideLowGames] = useState(0);

    useEffect(() => {
        if (playerData) {
          const newChampionDictionary = {};

          playerData.forEach((player) => {
            const championName = player.championName;

            if (!newChampionDictionary[championName]) {
              newChampionDictionary[championName] = {
                championName: championName,
                gameDuration: 0,
                matchesPlayed: 0,
                totalWins: 0,
                killsAndAssists8: 0,
                deaths8: 0,
                goldDiff8: 0,
                csDiff8: 0,
                expDiff8: 0,
                killsAndAssists14: 0,
                deaths14: 0,
                goldDiff14: 0,
                csDiff14: 0,
                expDiff14: 0,
                damageShared: 0,
                goldShared: 0,
                JungleProximity: 0,
                kills: 0,
                deaths: 0,
                assists: 0,
                DamageDealt: 0,
                GoldEarned: 0,
                CS: 0,
                KDA: 1,
                // Add more properties as needed
              };
            }

            newChampionDictionary[championName].matchesPlayed += 1;
            newChampionDictionary[championName].totalWins += player.winner;
            newChampionDictionary[championName].gameDuration += player.gameDuration;
            newChampionDictionary[championName].killsAndAssists8 += player.killsAndAssists8;
            newChampionDictionary[championName].deaths8 += player.deaths8;
            newChampionDictionary[championName].goldDiff8 += player.goldDiff8;
            newChampionDictionary[championName].csDiff8 += player.csDiff8;
            newChampionDictionary[championName].expDiff8 += player.expDiff8;
            newChampionDictionary[championName].killsAndAssists14 += player.killsAndAssists14;
            newChampionDictionary[championName].deaths14 += player.deaths14;
            newChampionDictionary[championName].goldDiff14 += player.goldDiff14;
            newChampionDictionary[championName].csDiff14 += player.csDiff14;
            newChampionDictionary[championName].expDiff14 += player.expDiff14;
            newChampionDictionary[championName].damageShared += player.damageShared;
            newChampionDictionary[championName].goldShared += player.goldShared;
            newChampionDictionary[championName].JungleProximity += player.JungleProximity;
            newChampionDictionary[championName].kills += player.kills;
            newChampionDictionary[championName].deaths += player.deaths;
            newChampionDictionary[championName].assists += player.assists;
            newChampionDictionary[championName].DamageDealt += player.DamageDealt;
            newChampionDictionary[championName].GoldEarned += player.GoldEarned;
            newChampionDictionary[championName].CS += player.CS;
          });

          for (const championName in newChampionDictionary) {
            const champion = newChampionDictionary[championName];
            for (const property in champion) {
              if (typeof champion[property] === 'number' && property !== 'matchesPlayed' && property !== 'championName' ) {
                if (["CS"].includes(property))
                {
                  champion[property] = (champion[property] / champion.gameDuration / champion.matchesPlayed  ).toFixed(2);
                }
                else            if (["GoldEarned", "DamageDealt"].includes(property))
                  {
                    champion[property] = (champion[property] / champion.gameDuration / champion.matchesPlayed  ).toFixed(0);
                  }
                else if(["totalWins"].includes(property))
                {
                  champion[property] = (champion[property] / champion.matchesPlayed * 100).toFixed(0);
                }
                else if(["damageShared", "goldShared", "JungleProximity"].includes(property))
                {
                  champion[property] = (champion[property] / champion.matchesPlayed * 100).toFixed(2);
                }
                else{
                champion[property] = (champion[property] / champion.matchesPlayed).toFixed(2);
                }
              }
            }


            // Calculate KDA
            const kills = parseInt(champion.kills);
            const assists = parseInt(champion.assists);
            const deaths = parseInt(champion.deaths);

            champion.KDA = ((kills + assists) / Math.max(deaths, 1)).toFixed(1);
          }


          setChampionDataDictionary(newChampionDictionary);
        }
      }, [playerData]);

      useEffect(() => {
        if (championDataDictionary) {
          const dataArray = Object.entries(championDataDictionary).map(([championName, championData]) => ({
            championName,
            ...championData
          }));

          const sortedArray = dataArray.sort(compareValues(sortedColumn, sortDirection));

          setSortedChampionData(sortedArray);
        }
      }, [championDataDictionary, sortedColumn, sortDirection]);

      const handleSort = (columnName) => {
        if (sortedColumn === columnName) {
          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
          setSortedColumn(columnName);
          setSortDirection('asc');
        }
      };

      const compareValues = (key, order = 'asc') => {
        return function (a, b) {
          if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
          }

          const varA = typeof a[key] === 'number' ? a[key] : parseFloat(a[key].replace(/[^0-9.-]+/g, ''));
          const varB = typeof b[key] === 'number' ? b[key] : parseFloat(b[key].replace(/[^0-9.-]+/g, ''));

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return order === 'desc' ? comparison * -1 : comparison;
        };
      };

      const getRedCellColor = (category, value) => {
        const maxValue = getMaxValue(category)
        const normalizedValue = value / maxValue;
        const hue = 0; // Hue value (green)
        const saturation = 100; // Saturation value (100%)
        const minLightness = 100; // Minimum Lightness value (100%)
        const maxLightness = 50; // Maximum Lightness value (50%)

        const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

        const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        return color;
      };

      const getGreenCellColor = (category, value) => {
        const maxValue = getMaxValue(category)
        const normalizedValue = value / maxValue;
        const hue = 128; // Hue value (green)
        const saturation = 100; // Saturation value (100%)
        const minLightness = 100; // Minimum Lightness value (100%)
        const maxLightness = 50; // Maximum Lightness value (50%)

        const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

        const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        return color;
      };

      const getRedGreenCell = (category, value) => {
        const maxValue = getMaxValue(category)
        const minValue = getMinValue(category)
        if (value === 0) {
          return `hsl(0, 0%, 100%)`; // White
        } else {
          const normalizedValue = value > 0 ? Math.abs(value) / maxValue : Math.abs(value / minValue); // Use absolute value

          // Define hue for the color gradient (e.g., green or red)
          const hue = value > 0 ? 120 : 0; // Green for positive, Red for negative

          // Interpolate lightness between 100% and 50% based on normalized value
          const lightness = 100 - 50 * normalizedValue;

          return `hsl(${hue}, 100%, ${lightness}%)`;
        }
      };

      const getRedGreenCellno0 = (category, value) => {
        const maxValue = getMaxValue(category)
        const minValue = getMinValue(category)
        if (value === (minValue + maxValue) / 2) {
          return `hsl(0, 0%, 100%)`; // White
        } else {
          const midpoint = (minValue + maxValue) / 2;
          const normalizedValue =
            value > midpoint
              ? (value - midpoint) / (maxValue - midpoint)
              : (-value + midpoint) / (minValue + midpoint);
          const hue = value > midpoint ? 120 : 0;
          const lightness = 100 - 50 * normalizedValue;
          return `hsl(${hue}, 100%, ${lightness}%)`;
        }
      };

      const getMaxValue = (property) => {
        return Math.max(...Object.values(championDataDictionary).map((player) => player[property]));
      };

      const getMinValue = (property) => {
        return Math.min(...Object.values(championDataDictionary).map((player) => player[property]));
      };

  return (
    <div style={{width: "100%"}}>
      <div style={{width: "100%"}}>
        <div className='filters'>
              {"Hide Under "}
              <label>
                <input
                  type="number"
                  value={hideLowGames} // Use value instead of checked
                  onChange={(e) => setHideLowGames(e.target.value)}                />
              </label>
          </div>
      </div>
    {championDataDictionary && (
      <table style={{ backgroundColor: "white", textAlign: "center", }}>
        <thead>
          <tr>
            <th></th>
            <th onClick={() => handleSort('championName')}>Champion</th>
            <th onClick={() => handleSort('matchesPlayed')}>Games</th>
            <th onClick={() => handleSort('totalWins')}>WR</th>
            <th onClick={() => handleSort('killsAndAssists8')}>K&A@8</th>
            <th onClick={() => handleSort('deaths8')}>D@8</th>
            <th onClick={() => handleSort('goldDiff8')}>G@8</th>
            <th onClick={() => handleSort('csDiff8')}>CSD@8</th>
            <th onClick={() => handleSort('expDiff8')}>XPD@8</th>
            <th onClick={() => handleSort('killsAndAssists14')}>K&A@14</th>
            <th onClick={() => handleSort('deaths14')}>D@14</th>
            <th onClick={() => handleSort('goldDiff14')}>GD@14</th>
            <th onClick={() => handleSort('csDiff14')}>CSD@14</th>
            <th onClick={() => handleSort('expDiff14')}>XPD@14</th>
            <th onClick={() => handleSort('damageShared')}>DMG%</th>
            <th onClick={() => handleSort('goldShared')}>G%</th>
            <th onClick={() => handleSort('JungleProximity')}>JGL%</th>
            <th onClick={() => handleSort('kills')}>K</th>
            <th onClick={() => handleSort('deaths')}>D</th>
            <th onClick={() => handleSort('assists')}>A</th>
            <th onClick={() => handleSort('KDA')}>KDA</th>
            <th onClick={() => handleSort('DamageDealt')}>DMG/M</th>
            <th onClick={() => handleSort('GoldEarned')}>G/M</th>
            <th onClick={() => handleSort('CS')}>CS/M</th>
          </tr>
        </thead>
        <tbody>
        {sortedChampionData
            .filter((championData) => hideLowGames <= championData.matchesPlayed) // Filter based on matchesPlayed
            .map((championData, champIndex) => (
            <tr key={champIndex}>
              <td><img className="small-image" src={championPhotos[championData.championName]} alt='' /></td>
              <td style={{ textAlign: "left",}}>{championData.championName}</td>
              <td style={{ backgroundColor: getGreenCellColor("matchesPlayed", championData.matchesPlayed) }}>{championData.matchesPlayed}</td>
              <td style={{ backgroundColor: getRedGreenCellno0("totalWins", championData.totalWins) }}>{championData.totalWins}%</td>
              <td style={{ backgroundColor: getGreenCellColor("killsAndAssists8", championData.killsAndAssists8) }}>{championData.killsAndAssists8}</td>
              <td style={{ backgroundColor: getRedCellColor("deaths8", championData.deaths8) }}>{championData.deaths8}</td>
              <td style={{ backgroundColor: getRedGreenCell("goldDiff8", championData.goldDiff8) }}>{championData.goldDiff8}</td>
              <td style={{ backgroundColor: getRedGreenCell("csDiff8", championData.csDiff8) }}>{championData.csDiff8}</td>
              <td style={{ backgroundColor: getRedGreenCell("expDiff8", championData.expDiff8) }}>{championData.expDiff8}</td>
              <td style={{ backgroundColor: getGreenCellColor("killsAndAssists14", championData.killsAndAssists14) }}>{championData.killsAndAssists14}</td>
              <td style={{ backgroundColor: getRedCellColor("deaths14", championData.deaths14) }}>{championData.deaths14}</td>
              <td style={{ backgroundColor: getRedGreenCell("goldDiff14", championData.goldDiff14) }}>{championData.goldDiff14}</td>
              <td style={{ backgroundColor: getRedGreenCell("csDiff14", championData.csDiff14) }}>{championData.csDiff14}</td>
              <td style={{ backgroundColor: getRedGreenCell("expDiff14", championData.expDiff14) }}>{championData.expDiff14}</td>
              <td style={{ backgroundColor: getRedGreenCellno0("damageShared", championData.damageShared) }}>{championData.damageShared}%</td>
              <td style={{ backgroundColor: getRedGreenCellno0("goldShared", championData.goldShared) }}>{championData.goldShared}%</td>
              <td style={{ backgroundColor: getRedGreenCellno0("JungleProximity", championData.JungleProximity) }}>{championData.JungleProximity}%</td>
              <td style={{ backgroundColor: getRedGreenCellno0("kills", championData.kills) }}>{championData.kills}</td>
              <td style={{ backgroundColor: getRedCellColor("deaths", championData.deaths) }}>{championData.deaths}</td>
              <td style={{ backgroundColor: getRedGreenCellno0("assists", championData.assists) }}>{championData.assists}</td>
              <td style={{ backgroundColor: getRedGreenCellno0("KDA", championData.KDA) }}>{championData.KDA}</td>
              <td style={{ backgroundColor: getRedGreenCellno0("DamageDealt", championData.DamageDealt) }} >{championData.DamageDealt}</td>
              <td style={{ backgroundColor: getRedGreenCellno0("GoldEarned", championData.GoldEarned) }}>{championData.GoldEarned}</td>
              <td style={{ backgroundColor: getRedGreenCellno0("CS", championData.CS) }}>{championData.CS}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
    </div>
  );
};

export default ChampionPerformanceComponent;
