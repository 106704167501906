import ocean from '../dragon_photos/ocean.png';
import chemtech from "../dragon_photos/chemtech.png";
import cloud from "../dragon_photos/cloud.png";
import elder from "../dragon_photos/elder.png";
import hextech from "../dragon_photos/hextech.png";
import infernal from "../dragon_photos/infernal.png";
import mountain from "../dragon_photos/infernal.png";

const dragonDictionary = {
    water:ocean,
    chemtech,
    air:cloud,
    elder,
    hextech,
    fire:infernal,
    earth:mountain
}

export default dragonDictionary;