import React, { useEffect, useState } from 'react';
import roleIcon from './Role';
import {championPhotos } from './ChampionsIcon';

const ChampionPoolComponnent = ({playerData}) => {
    const [playerChampionData, setPlayerChampionData] = useState([]);

    useEffect(() => {
        playerData && setPlayerChampionData(championDictionaryFunction(playerData));
    }, [playerData]);
      function championDictionaryFunction(matchData) {
        const championDictionary = {};
        if (!Array.isArray(matchData)) {
            console.log(matchData)
            console.error("Invalid matchData. Expected an array.");
            return championDictionary;
          }
        matchData.forEach((player) => {
          const role = 'A' + player.Role;
          const championName = player.championName;

          if (!championDictionary[role]) {
            championDictionary[role] = {};
          }

          if (!championDictionary[role][championName]) {
            championDictionary[role][championName] = {
              matchesPlayed: 0,
              totalWins: 0,
            };
          }

          championDictionary[role][championName].matchesPlayed += 1 ;
          championDictionary[role][championName].totalWins += player.winner;

        });

        // Calculate win rates and sort the champion data for each role
        for (const role in championDictionary) {
          const championsInRole = championDictionary[role];
          for (const championName in championsInRole) {
            const champion = championsInRole[championName];
            champion.winRate = (champion.totalWins * 100 / champion.matchesPlayed).toFixed(2);
          }

          const sortedChampions = Object.entries(championsInRole).sort((a, b) => b[1].matchesPlayed - a[1].matchesPlayed);
          championDictionary[role] = Object.fromEntries(sortedChampions);
        }

        return championDictionary;
      }

    const getRedGreenCellno0 = (value, minValue, maxValue) => {
        if (value === (minValue + maxValue) / 2) {
          return `hsl(0, 0%, 100%)`; // White
        } else {
          const midpoint = (minValue + maxValue) / 2;
          const normalizedValue =
            value > midpoint
              ? (value - midpoint) / (maxValue - midpoint)
              : (-value + midpoint) / (minValue + midpoint);

          // Define hue for the color gradient (e.g., green or red)
          const hue = value > midpoint ? 120 : 0; // Green for positive, Red for negative

          // Interpolate lightness between 100% and 50% based on normalized value
          const lightness = 100 - 50 * normalizedValue;

          return `hsl(${hue}, 100%, ${lightness}%)`;
        }
    };


  return (
    <div className='picks - by roles' style={{width: "100%"}}>
        <div className='Tables-per-role'>
        {Object.keys(playerChampionData).map((role, roleIndex) => (

            <div key={roleIndex} className='table-role'>
                <table style={{backgroundColor: "white"}}>
                    <thead>
                        <tr>
                            <th><img className="small-image" src={roleIcon[roleIndex]} alt=''/></th>
                            <th>Champion</th>
                            <th>Games</th>
                            <th>WR</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(playerChampionData[role]).map((championName, champIndex) => (
                        <tr key={champIndex}>
                            <td><img className="small-image" src={championPhotos[championName]} alt=''/></td>
                            <td>{championName}</td>
                            <td>{playerChampionData[role][championName].matchesPlayed}</td>
                            <td style={{backgroundColor: getRedGreenCellno0(playerChampionData[role][championName].winRate, 0, 100) }} >{playerChampionData[role][championName].winRate}%</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            ))}
        </div>
    </div>
  );
};

export default ChampionPoolComponnent;
