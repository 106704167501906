import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import roleIcon from './Role'; // Adjust the path accordingly
import './Player.css';
import { championPhotos } from "./ChampionsIcon";
import CustomSelect from './CustomSelect';
import 'react-pivottable/pivottable.css';
import TeamIcons from './TeamIcons';
import 'rc-slider/assets/index.css';
import ChampionPerformanceComponent from './PlayerChampionsPerformance';
import { useSeason } from './SeasonContext';
import HeatmapComponent from './WardHeatmap';
import ProximityPlayerComponent from './ProximityPlayer';

const PlayerPageScrim = () => {
  const {currentSeasonTime } = useSeason();
  const { name } = useParams();
  document.title = "SCRIM " + name;
  const [playerData, setPlayerData] = useState([]);
  const [filterPlayerData, setFilterPlayerData] = useState([]);

  const [proximityData, setProximityData] = useState([]);
  const [filteredProximityData, setFilteredProximityData] = useState([]);

  const [teamMatesData, setTeamMatesData] = useState([]);
  const [wardData, setWardData] = useState([]);
  const encodedPlayerName = encodeURIComponent(name);

  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  // Set the initial state with the calculated date
  const [startDate, setStartDate] = useState(twoWeeksAgo);
  const [endDate, setEndDate] = useState(new Date());

  const [championDictionary, setChampionDictionary] = useState({});

  const [filteredPlayerData, setFilteredPlayerData] = useState({});


  useEffect(() => {
    // Fetch player data based on the player name from the URL
    axios
      .get(`https://sanchidataportal.com/api/playersScrims/?name=${encodedPlayerName}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setPlayerData(sortedData);
        const uniquePatches = [...new Set(sortedData.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
      })
      .catch(error => {
        console.error(error);
      });

      axios
      .get(`https://sanchidataportal.com/api/proximityScrims/?name=${encodedPlayerName}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setProximityData(sortedData);
      })
      .catch(error => {
        console.error(error);
      });

    axios
      .get(`https://sanchidataportal.com/api/playerwards/?name=${encodedPlayerName}`)
      .then(response => {
        setWardData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
    }, []);

  useEffect(() => {
    const teamName = name.split(" ")[0];
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axios.get(`https://sanchidataportal.com/api/teammates/?name=${encodeURIComponent(teamName)}`+ '&start=' + start + "&end=" + end)
        .then(response => {
          const sortedData = response.data.sort((a, b) => a.Role - b.Role);
          setTeamMatesData(sortedData);
        })
        .catch(error => {
          console.error(error);
        });
    }, [name, currentSeasonTime]);


    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          const matchDate = new Date(player.date);
          return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) && isPatchGood;
        });
        setFilteredPlayerData(filteredPlayersPick);
        const newChampionDictionary = {};

        // Iterate through the filtered player data to update the champion dictionary
        filteredPlayersPick.forEach((player) => {
          const championName = player.championName;

          if (!newChampionDictionary[championName]) {
            newChampionDictionary[championName] = {
              matchesPlayed: 0,
              totalWins: 0,
            };
          }

          newChampionDictionary[championName].matchesPlayed += 1;
          newChampionDictionary[championName].totalWins += player.winner;
        });

        // Calculate win rates and sort the champion data
        for (const championName in newChampionDictionary) {
          const champion = newChampionDictionary[championName];
          champion.winRate = ((champion.totalWins * 100) / champion.matchesPlayed).toFixed(2);
        }

        const sortedChampions = Object.entries(newChampionDictionary).sort(
          (a, b) => b[1].matchesPlayed - a[1].matchesPlayed
        );

        // Map the sorted entries back to an object
        const sortedChampionObject = Object.fromEntries(sortedChampions);

        setFilterPlayerData(filteredPlayersPick);
        setChampionDictionary(sortedChampionObject);
      }
    }, [playerData, filterPatch, startDate, endDate]);

    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          const matchDate = new Date(player.date);
          return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) &&  isPatchGood;
        });
      }
      if (proximityData) {
        const filteredProximityData1 = proximityData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          return isPatchGood;
        });
        setFilteredProximityData(filteredProximityData1);
      }
    }, [playerData, filterPatch, startDate, endDate, proximityData]);



  const handlePatchChange = (selectedOptions) => {
    //setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleClick = (teamName) => {
    const searchQuery = encodeURIComponent(teamName); // Encode the value for URL
    window.location.href = `/player/${searchQuery}`;
  };

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // If the same column is clicked, reverse the sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different column is clicked, set it as the sorted column and default to ascending order
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
    setEndDate(selectedDate);
  };

  function calculateAverageDamagePerMinute(player) {
    return (player.DamageDealt / player.gameDuration).toFixed(0);
  }

  function calculateAverageCSPerMinute(player) {
    return (player.CS / player.gameDuration).toFixed(2);
  }

  function calculateAverageGoldPerMinute(player) {
    return (player.GoldEarned / player.gameDuration).toFixed(0);
  }

  const sortedFilterPlayerData = filterPlayerData.slice().sort((a, b) => {
    a.averageCSPerMinute = calculateAverageCSPerMinute(a);
    b.averageCSPerMinute = calculateAverageCSPerMinute(b);

    a.averageGoldPerMinute = calculateAverageGoldPerMinute(a);
    b.averageGoldPerMinute = calculateAverageGoldPerMinute(b);
    if (sortedColumn === 'damageShared' || sortedColumn === 'goldShared' || sortedColumn === 'JungleProximity') {
      // Handle sorting for percentage columns
      const aValue = a[sortedColumn] * 100;
      const bValue = b[sortedColumn] * 100;
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (sortedColumn === 'DamageDealt')
    {
      const aValue = calculateAverageDamagePerMinute(a);
      const bValue = calculateAverageDamagePerMinute(b);
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }
    else if (sortedColumn === 'CS') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageCSPerMinute) - parseFloat(b.averageCSPerMinute)
      : parseFloat(b.averageCSPerMinute) - parseFloat(a.averageCSPerMinute);
    } else if (sortedColumn === 'GoldEarned') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageGoldPerMinute) - parseFloat(b.averageGoldPerMinute)
      : parseFloat(b.averageGoldPerMinute) - parseFloat(a.averageGoldPerMinute);
   }
    else if (sortedColumn === 'championName' || sortedColumn === 'enemyChampionName') {

      const aValue = a[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const bValue = b[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    else
    {
      // Handle sorting for other columns
      return sortDirection === 'asc'
        ? a[sortedColumn] - b[sortedColumn]
        : b[sortedColumn] - a[sortedColumn];
    }
  });


  function averageValue(columnName) {
    const total = filterPlayerData.reduce((acc, record) => acc + record[columnName], 0);

    return (total / filterPlayerData.length); // You can adjust the number of decimal places
  }

  const getMaxValueDivided = (property, divisor) => {
    return Math.max(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };

  const getMinValueDivided = (property, divisor) => {
    return Math.min(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };


  const getMaxValue = (property) => {
    return Math.max(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const maxKillsAndAssists8 = getMaxValue('killsAndAssists8');
  const maxDeaths8 = getMaxValue('deaths8');

  const maxGoldDiff8 = getMaxValue('goldDiff8');
  const minGoldDiff8 = getMinValue('goldDiff8');

  const maxCsDiff8 = getMaxValue('csDiff8');
  const minCsDiff8 = getMinValue('csDiff8');

  const maxExpDiff8 = getMaxValue('expDiff8');
  const minExpDiff8 = getMinValue('expDiff8');

  const maxKillsAndAssists14 = getMaxValue('killsAndAssists14');
  const maxDeaths14 = getMaxValue('deaths14');

  const maxGoldDiff14 = getMaxValue('goldDiff14');
  const minGoldDiff14 = getMinValue('goldDiff14');

  const maxCsDiff14 = getMaxValue('csDiff14');
  const minCsDiff14 = getMinValue('csDiff14');

  const maxExpDiff14 = getMaxValue('expDiff14');
  const minExpDiff14 = getMinValue('expDiff14');

  const maxDamageShared = getMaxValue('damageShared');
  const minDamageShared = getMinValue('damageShared');

  const maxGoldShared = getMaxValue('goldShared');
  const minGoldShared = getMinValue('goldShared');

  const maxJungleProximity = getMaxValue('JungleProximity');
  const minJungleProximity = getMinValue('JungleProximity');

  const maxDamageDealt = getMaxValueDivided('DamageDealt','gameDuration');
  const minDamageDealt = getMinValueDivided('DamageDealt', 'gameDuration');

  const maxCS = getMaxValueDivided('CS', 'gameDuration');
  const minCS = getMinValueDivided('CS', 'gameDuration');

  const maxGoldEarned = getMaxValueDivided('GoldEarned', 'gameDuration');
  const minGoldEarned = getMinValueDivided('GoldEarned', 'gameDuration');

  const maxK = getMaxValue('kills');

  const maxD = getMaxValue('deaths');

  const maxA = getMaxValue('assists');

  const getRedCellColor = (value, maxValue) => {
    const normalizedValue = value / maxValue;
    const hue = 0; // Hue value (green)
    const saturation = 100; // Saturation value (100%)
    const minLightness = 100; // Minimum Lightness value (100%)
    const maxLightness = 50; // Maximum Lightness value (50%)

    const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  };

  const getGreenCellColor = (value, maxValue) => {
    const normalizedValue = value / maxValue;
    const hue = 128; // Hue value (green)
    const saturation = 100; // Saturation value (100%)
    const minLightness = 100; // Minimum Lightness value (100%)
    const maxLightness = 50; // Maximum Lightness value (50%)

    const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  };

  const getRedGreenCell = (value, minValue, maxValue) => {
    if (value === 0) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const normalizedValue = value > 0 ? Math.abs(value) / maxValue : Math.abs(value / minValue); // Use absolute value

      // Define hue for the color gradient (e.g., green or red)
      const hue = value > 0 ? 120 : 0; // Green for positive, Red for negative

      // Interpolate lightness between 100% and 50% based on normalized value
      const lightness = 100 - 50 * normalizedValue;

      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  const getRedGreenCellno0 = (value, minValue, maxValue) => {
    if (value === (minValue + maxValue) / 2) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const midpoint = (minValue + maxValue) / 2;
      const normalizedValue =
        value > midpoint
          ? (value - midpoint) / (maxValue - midpoint)
          : (-value + midpoint) / (minValue + midpoint);

      // Define hue for the color gradient (e.g., green or red)
      const hue = value > midpoint ? 120 : 0; // Green for positive, Red for negative

      // Interpolate lightness between 100% and 50% based on normalized value
      const lightness = 100 - 50 * normalizedValue;

      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  const handleGameClick = (teamName) => {
    window.location.href = `/scrim/${teamName}`;
  };

  const handleClickTeam = (teamName) => {
    window.location.href = `/scrimteam/${teamName}`;
  };

  return (
    <div className='main' >
      <div style={{width:"100%", textAlign: "center", color: "white"}}>
      Scrims
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', color: "black", width: "100%", backgroundColor: "white", marginBottom: "10px", alignItems: "center", textAlign: "center", borderBottom: "2px solid rgb(0, 242, 255)" }}>
        <button onClick={() => handleClickTeam(name.split(" ")[0])}>Go To <img className='small-image' src={TeamIcons[name.split(" ")[0]]} alt="" />{name.split(" ")[0]}</button>
        <div>
          {name}
          <img className='small-image' src={TeamIcons[name.split(" ")[0]]} alt="" />
        </div>
        <button onClick={() => handleClick(name)}>GO TO OFFICIALS</button>
      </div>

      <div style={{ alignItems: "center" }}>
        {teamMatesData && (
          <div className="teammates-container">
            <div style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}  >
              {teamMatesData.map(teammate => (
                <a
                  key={teammate.summonerName}
                  href={`/playerscrim/${encodeURIComponent(teammate.summonerName)}`}
                  className="teammate-link-2"
                >
                  <div className="teammate">
                    <img
                      src={roleIcon[teammate.Role]}
                      alt={`Role ${teammate.Role} Icon`}
                      className="role-icon"
                    />
                    <span className="teammate-name-2">{teammate.summonerName}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className='filters'>
        Starting Date
        <input
          type="date"
          value={startDate?.toISOString().split('T')[0]}
          onChange={handleStartDateChange}
        />
        Ending Date
        <input
          type="date"
          value={endDate?.toISOString().split('T')[0]}
          onChange={handleEndDateChange}
        />
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>

      </div>

      <div className='team-perfromance-by-game' style={{width: "100%"}}>
        {filterPlayerData ? (
          <table className='team-perfromance-by-game-table'>
            <thead>
              <tr>
                <th onClick={() => handleSort('championName')}>championName</th>
                <th onClick={() => handleSort('enemyChampionName')}>enemyName</th>
                <th onClick={() => handleSort('winner')}>WR%</th>
                <th onClick={() => handleSort('killsAndAssists8')}>K&A@8</th>
                <th onClick={() => handleSort('deaths8')}>D@8</th>
                <th onClick={() => handleSort('goldDiff8')}>GD@8</th>
                <th onClick={() => handleSort('csDiff8')}>CSD@8</th>
                <th onClick={() => handleSort('expDiff8')}>XPD@8</th>
                <th onClick={() => handleSort('killsAndAssists14')}>K&A@14</th>
                <th onClick={() => handleSort('deaths14')}>D@14</th>
                <th onClick={() => handleSort('goldDiff14')}>GD@14</th>
                <th onClick={() => handleSort('csDiff14')}>CSD@14</th>
                <th onClick={() => handleSort('expDiff14')}>XPD@14</th>
                <th onClick={() => handleSort('damageShared')}>DMG%</th>
                <th onClick={() => handleSort('goldShared')}>G%</th>
                <th onClick={() => handleSort('JungleProximity')}>JGL Prox</th>
                <th onClick={() => handleSort('kills')}>K</th>
                <th onClick={() => handleSort('deaths')}>D</th>
                <th onClick={() => handleSort('assists')}>A</th>
                <th onClick={() => handleSort('DamageDealt')}>DMG/M</th>
                <th onClick={() => handleSort('GoldEarned')}>G/M</th>
                <th onClick={() => handleSort('CS')}>CS/M</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedFilterPlayerData.map((record, index) => {

                  const damagePerMinute = (record.DamageDealt / record.gameDuration).toFixed(0);
                  const csPerMinute = (record.CS / record.gameDuration).toFixed(2);
                  const goldPerMinute = (record.GoldEarned / record.gameDuration).toFixed(0);
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left', justifyContent: 'left' }}><img className='small-image' src={championPhotos[record.championName]} alt="" />{record.championName}</td>
                    <td style={{ textAlign: 'left' }}><img className='small-image' src={championPhotos[record.enemyChampionName]} alt="" />{record.enemyChampionName}</td>
                    <td>{record.winner ? 'Yes' : 'No'}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists8, maxKillsAndAssists8) }}>{record.killsAndAssists8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths8, maxDeaths8) }}>{record.deaths8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff8, minGoldDiff8, maxGoldDiff8)}}>{record.goldDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff8, minCsDiff8, maxCsDiff8)}}>{record.csDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff8, minExpDiff8, maxExpDiff8)}}>{record.expDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists14, maxKillsAndAssists14) }}>{record.killsAndAssists14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths14, maxDeaths14) }}>{record.deaths14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff14, minGoldDiff14, maxGoldDiff14)}}>{record.goldDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff14, minCsDiff14, maxCsDiff14)}}>{record.csDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff14, minExpDiff14, maxExpDiff14)}}>{record.expDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.damageShared, minDamageShared, maxDamageShared)}}>{(record.damageShared*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.goldShared, minGoldShared, maxGoldShared)}}>{(record.goldShared*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.JungleProximity, minJungleProximity, maxJungleProximity)}}>{(record.JungleProximity*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.kills, maxK) }}>{record.kills}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths, maxD) }}>{record.deaths}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.assists, maxA) }}>{record.assists}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(damagePerMinute, minDamageDealt, maxDamageDealt)}}>{(damagePerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(goldPerMinute, minGoldEarned, maxGoldEarned)}}>{(goldPerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(csPerMinute, minCS, maxCS)}}>{csPerMinute}</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleGameClick(record.scrimId)}>{"->"}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr style={{backgroundColor: "white"}}>
                <td>Average</td>
                <td></td>
                <td></td>
                <td>{averageValue('killsAndAssists8').toFixed(2)}</td>
                <td>{averageValue('deaths8').toFixed(2)}</td>
                <td>{(averageValue('goldDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff8')*1).toFixed(0)}</td>
                <td>{averageValue('killsAndAssists14').toFixed(2)}</td>
                <td>{averageValue('deaths14').toFixed(2)}</td>
                <td>{(averageValue('goldDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('damageShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('goldShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('JungleProximity') * 100).toFixed(2)}%</td>
                <td>{averageValue('kills').toFixed(1)}</td>
                <td>{averageValue('deaths').toFixed(1)}</td>
                <td>{averageValue('assists').toFixed(1)}</td>
                <td>{(averageValue('DamageDealt') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('GoldEarned') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('CS') / averageValue('gameDuration')).toFixed(2)}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        ) : (
          <p>Loading...</p>
        )}

      </div>

      <div>
        { championDictionary && (
        <table style={{backgroundColor: "white"}}>
         <thead>
            <tr>
              <th></th>
              <th>Champion</th>
              <th>Games</th>
              <th>WR</th>
            </tr>
          </thead>
            <tbody>
              {Object.entries(championDictionary).map(([championName, championData], champIndex) => (
                <tr key={champIndex}>
                  <td><img className="small-image" src={championPhotos[championName]} alt='' /></td>
                  <td>{championName}</td>
                  <td>{championData.matchesPlayed}</td>
                  <td>{championData.winRate}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div style={{width: "100%"}}>
        <ChampionPerformanceComponent playerData={filterPlayerData}/>
      </div>

      {filteredProximityData && filteredProximityData.length > 0 && (
        <ProximityPlayerComponent proximityData={filteredProximityData} />
      )}

      { wardData ? (
        <>
          <div style={{width: "100%", height: "1000px", color: "white", textAlign: "center", alignItems:"center"}}>
          <br></br>
                  <h1>WARDS HEATMAP</h1>
                  <HeatmapComponent wardData={wardData} />
          </div>
        </>
      ):(
        <>
        Loading..
        </>
      )}
    </div>
  );
};
export default PlayerPageScrim;
