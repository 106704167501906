import icon3BL from "../team_icons/3BL.png";
import icon5R from "../team_icons/5R.png";
import icon7AM from "../team_icons/7AM.png";
import icon4W from "../team_icons/4W.png";
import icon1M from "../team_icons/1M.png";
import AEG from '../team_icons/AEG.png';
import AGC from '../team_icons/AGC.png';
import ANB from '../team_icons/ANB.png';
import ANK from '../team_icons/ANK.png';
import ANO from '../team_icons/ANO.png';
import AXL from '../team_icons/AXL.png';
import BAR from '../team_icons/BAR.png';
import BDSA from '../team_icons/BDSA.png';
import BFC from '../team_icons/BFC.png';
import BIG from '../team_icons/BIG.png';
import BJK from '../team_icons/BJK.png';
import BKR from '../team_icons/BKR.png';
import BLX from '../team_icons/BLX.png';
import BRT from '../team_icons/BRT.png';
import BSO from '../team_icons/BSO.png';
import CGG from '../team_icons/CGG.png';
import CWE from '../team_icons/CWE.png';
import CZV from '../team_icons/CZV.png';
import DIA from '../team_icons/DIA.png';
import DMN from '../team_icons/DMN.png';
import DNE from '../team_icons/DNE.png';
import DP from '../team_icons/DP.png';
import DREN from '../team_icons/DREN.png';
import DSYR from '../team_icons/DSYR.png';
import EGN from '../team_icons/EGN.png';
import EINS from '../team_icons/EINS.png';
import EIQ from '../team_icons/EIQ.png';
import ESB from '../team_icons/ESB.png';
import ESCA from '../team_icons/ESCA.png';
import EWI from '../team_icons/EWI.png';
import EXD from '../team_icons/EXD.png';
import FNK from '../team_icons/FNK.png';
import FNTQ from '../team_icons/FNTQ.png';
import FTW from '../team_icons/FTW.png';
import FUT from '../team_icons/FUT.png';
import FXG from '../team_icons/FXG.png';
import GAL from '../team_icons/GAL.png';
import GENK from '../team_icons/GENK.png';
import GGE from '../team_icons/GGE.png';
import GIA from '../team_icons/GIA.png';
import GK from '../team_icons/GK.png';
import GL from '../team_icons/GL.png';
import GNG from '../team_icons/GNG.png';
import GO from '../team_icons/GO.png';
import GRP from '../team_icons/GRP.png';
import GSMC from '../team_icons/GSMC.png';
import GSNS from '../team_icons/GSNS.png';
import GTZ from '../team_icons/GTZ.png';
import GW from '../team_icons/GW.png';
import HEET from '../team_icons/HEET.png';
import HRTS from '../team_icons/HRTS.png';
import IHG from '../team_icons/IHG.png';
import ING from '../team_icons/ING.png';
import IW from '../team_icons/IW.png';
import IZI from '../team_icons/IZI.png';
import KC from '../team_icons/KC.png';
import LDLC from '../team_icons/LDLC.png';
import MCK from '../team_icons/MCK.png';
import MCON from '../team_icons/MCON.png';
import MFSK from '../team_icons/MFSK.png';
import MGZ from '../team_icons/MGZ.png';
import MOUZ from '../team_icons/MOUZ.png';
import MRS from '../team_icons/MRS.png';
import NGX from '../team_icons/NGX.png';
import NLE from '../team_icons/NLE.png';
import NNO from '../team_icons/NNO.png';
import NORD from '../team_icons/NORD.png';
import NSR from '../team_icons/NSR.png';
import NT from '../team_icons/NT.png';
import OP from '../team_icons/OP.png';
import OSC from '../team_icons/OSC.png';
import OVA from '../team_icons/OVA.png';
import PAO from '../team_icons/PAO.png';
import PAR from '../team_icons/PAR.png';
import PR from '../team_icons/PR.png';
import RAAD from '../team_icons/RAAD.png';
import RBLS from '../team_icons/RBLS.png';
import RDL from '../team_icons/RDL.png';
import RUD from '../team_icons/RUD.png';
import S04 from '../team_icons/S04.png';
import SGE from '../team_icons/SGE.png';
import SIN from '../team_icons/SIN.png';
import SLY from '../team_icons/SLY.png';
import SMP from '../team_icons/SMP.png';
import SUP from '../team_icons/SUP.png';
import TP from '../team_icons/TP.png';
import TRP from '../team_icons/TRP.png';
import UCAM from '../team_icons/UCAM.png';
import UNIQ from '../team_icons/UNIQ.png';
import USE from '../team_icons/USE.png';
import VAE from '../team_icons/VAE.png';
import VER from '../team_icons/VER.png';
import VITB from '../team_icons/VITB.png';
import VNR from '../team_icons/VNR.png';
import VPG from '../team_icons/VPG.png';
import WBD from '../team_icons/WBD.png';
import WD from '../team_icons/WD.png';
import WLG from '../team_icons/WLG.png';
import WOLF from '../team_icons/WOLF.png';
import WPE from '../team_icons/WPE.png';
import XD from '../team_icons/XD.png';
import Z10 from '../team_icons/Z10.png';
import ZNT from '../team_icons/ZNT.png';
import ZRL from '../team_icons/ZRL.png';
import OAE from '../team_icons/OAE.png';
import AB from '../team_icons/AB.png';
import KNF from '../team_icons/KNF.png';
import NASR from '../team_icons/NASR.png';
import EF from '../team_icons/EF.png';
import SKP from '../team_icons/SKP.png';
import ATE from '../team_icons/ATE.png';
import NM3 from '../team_icons/NM3.png';
import AOMA from '../team_icons/AOMA.png';
import MYTH from '../team_icons/MYTH.png';
import SPK from '../team_icons/SPK.png';
import HELL from '../team_icons/HELL.png';
import PTR from '../team_icons/PTR.png';
import NTZ from '../team_icons/NTZ.png';
import BWE from '../team_icons/BWE.png';
import KYP from '../team_icons/KYP.png';
import B2TG from '../team_icons/B2TG.png';
import DV1 from '../team_icons/DV1.png';
import DCT from '../team_icons/DCT.png';
import ENS from '../team_icons/ENS.png';
import EUS from '../team_icons/EUS.png';
import LDS from '../team_icons/LDS.png';
import LTG from '../team_icons/LTG.png';
import MVE from '../team_icons/MVE.png';
import NRAX from '../team_icons/NRAX.png';
import TFH from '../team_icons/TFH.png';

import AST from '../team_icons/AST.png';
import BDS from '../team_icons/BDS.png';
import FNC from '../team_icons/FNC.png';
import G2 from '../team_icons/G2.png';
import TH from '../team_icons/TH.png';
import KOI from '../team_icons/KOI.png';
import MAD from '../team_icons/MAD.png';
import SK from '../team_icons/SK.png';
import VIT from '../team_icons/VIT.png';
import XL from '../team_icons/XL.png';
import ROAR from '../team_icons/ROAR.png';
import T1 from '../team_icons/T1.png';
import BLG from '../team_icons/BLG.png';
import WBG from '../team_icons/WBG.png';
import JDG from '../team_icons/JDG.png';
import LNG from '../team_icons/LNG.png';
import FAK from '../team_icons/FAK.png';
import SGB from '../team_icons/SGB.png';
import PSG from '../team_icons/PSG.png';
import TW from '../team_icons/TW.png';
import MISA from '../team_icons/MISA.png';
import ZTA from '../team_icons/ZTA.png';
import CFO from '../team_icons/CFO.png';
import BYG from '../team_icons/BYG.png';
import JT from '../team_icons/JT.png';
import MDK from '../team_icons/MDK.png';
import RGE from '../team_icons/RGE.png';
import GX from '../team_icons/GX.png';
import RBT from '../team_icons/RBT.png';
import LC from '../team_icons/LC.png';
import DSY from '../team_icons/DSY.png';
import M8 from '../team_icons/M8.png';
import WP from '../team_icons/WP.png';
import DCG from '../team_icons/DCG.png';
import BRO from '../team_icons/BRO.png';
import KT from '../team_icons/KT.png';
import DK from '../team_icons/DK.png';
import KDF from '../team_icons/KDF.png';
import HLE from '../team_icons/HLE.png';
import DRX from '../team_icons/DRX.png';
import AFW from '../team_icons/AFW.png';
import CASE from '../team_icons/CASE.png';
import FOX from '../team_icons/FOX.png';
import TDS from '../team_icons/TDS.png';
import GEN from '../team_icons/GEN.png';
import NS from '../team_icons/NS.png';
import HPS from '../team_icons/HPS.png';
import DFM from '../team_icons/DFM.png';
import SHG from '../team_icons/SHG.png';
import GAM from '../team_icons/GAM.png';
import BGT from '../team_icons/BGT.png';

import MEC from '../team_icons/MEC.png';
import GZ from '../team_icons/GZ.png';
import ION from '../team_icons/ION.png';
import TB from '../team_icons/TB.png';
import DW from '../team_icons/DW.png';
import KNG from '../team_icons/KNG.png';
import FRY from '../team_icons/FRY.png';
import ANC from '../team_icons/ANC.png';

import V3 from '../team_icons/V3.png';
import AXC from '../team_icons/AXC.png';
import BCT from '../team_icons/BCT.png';
import SG from '../team_icons/SG.png';

//LPL
import NIP from '../team_icons/NIP.png';
import TES from '../team_icons/TES.png';
import IG from '../team_icons/IG.png';
import WE from '../team_icons/WE.png';
import FPX from '../team_icons/FPX.png';
import EDG from '../team_icons/EDG.png';
import RA from '../team_icons/RA.png';
import OMG from '../team_icons/OMG.png';
import LGD from '../team_icons/LGD.png';
import AL from '../team_icons/AL.png';
import RNG from '../team_icons/RNG.png';
import TT from '../team_icons/TT.png';
import UP from '../team_icons/UP.png';
import HKF from '../team_icons/HKF.png';
import IMP from '../team_icons/IMP.png';
import TNU from '../team_icons/TNU.png';
import UCH from '../team_icons/UCH.png';

import TF from '../team_icons/TF.png';
import TS from '../team_icons/TS.png';
import VKE from '../team_icons/VKE.png';
import MBE from '../team_icons/MBE.png';
import RW from '../team_icons/RW.png';
import CES from '../team_icons/CES.png';
import GLR from '../team_icons/GLR.png';
import BE5 from '../team_icons/BE5.png';
import LUA from '../team_icons/LUA.png';
import TWIS from '../team_icons/TWIS.png';
import F0X from '../team_icons/F0X.png';
import PHX from '../team_icons/PHX.png';
import KTN from '../team_icons/KTN.png';
import NON from '../team_icons/NON.png';
import LY from '../team_icons/LY.png';
import SNZ from '../team_icons/SNZ.png';
import OUAT from '../team_icons/OUAT.png';
import DYN from '../team_icons/DYN.png';
import KE from '../team_icons/KE.png';
import MHSC from '../team_icons/MHSC.png';
import ZER from '../team_icons/ZER.png';
import ES from '../team_icons/ES.png';
import IJC from '../team_icons/IJC.png';
import JL from '../team_icons/JL.png';
import PCS from '../team_icons/PCS.png';
import HLC from '../team_icons/HLC.png';
import CHG from '../team_icons/CHG.png';
import VENS from '../team_icons/VENS.png';
import ASC from '../team_icons/ASC.png';
import IMU from '../team_icons/IMU.png';
import MCER from '../team_icons/MCER.png';
import KRS from '../team_icons/KRS.png';
import FLY from '../team_icons/FLY.png';
import C9 from '../team_icons/C9.png';
import DIG from '../team_icons/DIG.png';
import TL from '../team_icons/TL.png';
import png100 from '../team_icons/100.png';
import IMT from '../team_icons/IMT.png';
import NRG from '../team_icons/NRG.png';
import SR from '../team_icons/SR.png';
import VNC from '../team_icons/VNC.png';
import MY from '../team_icons/MY.png';

export const TeamIcons = {
    MY,
    VNC,
    "100": png100, FLY, C9, DIG, TL, IMT, SR, NRG,
    KE, MHSC, ZER, ES, IJC, PCS, JL,
    "1M":icon1M, CHG, HLC,
    GNG,TWIS, F0X, PHX, KTN, NON, LY, SNZ, OUAT, DYN,
    LUA, VENS, ASC, IMU, MCER, KRS,
    BE5, GLR,
    TF, TS, VKE, MBE, RW, CES,
    NIP, FPX, IG, TES, WE, EDG, RA, OMG, LGD, TT, RNG, AL, UP,
    MEC, GZ, ION, TB, DW, KNG, FRY, ANC,
    AXCA:AXC, FAKA:FAK, PSGA:PSG, DCGA:DCG, WPA:WP, V3A:V3, SGA:SG, HKF, IMP, TNU, UCH, CFOA:CFO,
    V3, AXC, BCT, SG, BCTA:BCT,
    FSK:MFSK,
    GAM,
    BGT,
    SHG,
    DFM,
    DFMA:DFM,
    FAKU:FAK,
    NS,
    HPS,
    GEN,
    KCB:KC,
    TDS,
    FOX,
    GXP:GX,
    CASE,
    AFW,
    DRX,
    HLE,
    KDF,
    DK,
    KT,
    BRO,
    M8,
    DCG,
    WP,
    LC,
    DSY,
    NXT:MOUZ,
    RBT,
    MDK,
    RGE,
    GX,
    JT,
    BYG,
    CFO,
    ZTA,
    MISA,
    TW,
    PSG,
    SGB,
    FAK,
    LNG,
    BLG,
    T1,
    WBG,
    JDG,
    ROAR,
    AST,
    BDS,
    FNC,
    G2,
    TH,
    KOI,
    MAD,
    SK,
    VIT,
    XL,
    B2TG,
    DV1,
    DCT,
    ENS,
    EUS,
    LDS,
    LTG,
    MVE,
    NRAX,
    TFH,
    '3BL': icon3BL,
    '5R': icon5R,
    '7AM': icon7AM,
    '4W': icon4W,
    KYP,
    SKP,
    BWE,
    NTZ,
    PTR,
    HELL,
    SPK,
    NM3,
    AOMA,
    MYTH,
    ATE,
    EF,
    KNF,
    NASR,
    AB,
    OAE,
    AEG,
    AGC,
    ANB,
    ANK,
    ANO,
    AXL,
    BAR,
    BDSA,
    BFC,
    BIG,
    BJK,
    BKR,
    BLX,
    BRT,
    BSO,
    CGG,
    CWE,
    CZV,
    DIA,
    DMN,
    DNE,
    DP,
    DREN,
    DSYR,
    EGN,
    EINS,
    EIQ,
    ESB,
    ESCA,
    EWI,
    EXD,
    FNK,
    FNTQ,
    FTW,
    FUT,
    FXG,
    GAL,
    GENK,
    GGE,
    GIA,
    GK,
    GL,
    GO,
    GRP,
    GSMC,
    GSNS,
    GTZ,
    GW,
    HEET,
    HRTS,
    IHG,
    ING,
    IW,
    IZI,
    KC,
    LDLC,
    MCK,
    MCON,
    MFSK,
    MGZ,
    MOUZ,
    MRS,
    NGX,
    NLE,
    NNO,
    NORD,
    NSR,
    NT,
    OP,
    OSC,
    OVA,
    PAO,
    PAR,
    PR,
    RAAD,
    RBLS,
    RDL,
    RUD,
    S04,
    SGE,
    SIN,
    SLY,
    SMP,
    SUP,
    TP,
    TRP,
    UCAM,
    UNIQ,
    USE,
    VAE,
    VER,
    VITB,
    VNR,
    VPG,
    WBD,
    WD,
    WLG,
    WOLF,
    WPE,
    XD,
    Z10,
    ZNT,
    ZRL,
};

export default TeamIcons;