import React, { useState, useEffect, useRef, forwardRef, createRef, useCallback, useImperativeHandle } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Include necessary CSS for toast notifications
import { championData, championPhotos } from "./ChampionsIcon";
import TeamIcons from './TeamIcons';
import html2canvas from 'html2canvas'
import roleIcon from './Role';
const Table = forwardRef(({ data, tableIndex, onDraftChange, setStoredDraft, storedDraft, storedNames, setStoredNames, tierlistOpen}, ref) => {
  const [blueChampionNames, setBlueChampionNames] = useState(new Array(11).fill(''));
  const [redChampionNames, setRedChampionNames] = useState(new Array(11).fill(''));
  const [description, setDescription] = useState(); // State for description
  const [propositions, setPropositions] = useState([]);
  const [teamPropositions, setTeamPropositions] = useState([]);
  const [history, setHistory] = useState([]);

  useImperativeHandle(ref, () => ({
    clearTable: handleClearTableButtonClick, // Expose the handleClearTableButtonClick function
  }));

  useEffect(() => {
      if (data.length > 0) {
        const updatedBlueChampionNames = data.map((draft) => {
          const blueChampions = ['B0', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10'];
          const blueChampionNamesArray = blueChampions.map((championKey) => draft[championKey] || '');
          return blueChampionNamesArray;
        });

        const updatedRedChampionNames = data.map((draft) => {
          const redChampions = ['R0', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10'];
          const redChampionNamesArray = redChampions.map((championKey) => draft[championKey] || '');
          return redChampionNamesArray;
        });
        setBlueChampionNames(updatedBlueChampionNames);
        setRedChampionNames(updatedRedChampionNames);
        setDescription(data[0].description)
      }
    }, [data]);

    const captureScreenshot = (table) => {
      const element = document.getElementById(table);
      html2canvas(element)
        .then((canvas) => {
          const image = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'screenshot.png';
          link.href = image;
          link.click();
        })
        .catch(err => {
          console.error('Error capturing screenshot', err);
        });
    };

    const handleCopyDraft = () => {
      const draftData = {
        blueChampionNames: blueChampionNames[0],
        redChampionNames: redChampionNames[0],
        description
      };
      setStoredNames(draftData);
      const draftToCopy = data;
      setStoredDraft({ ...draftToCopy }[0]);
    };

    const handlePasteBans = () => {
      if (storedDraft) {
        setHistory(prevHistory => [...prevHistory, { blueChampionNames, redChampionNames, description }]);
        const newDraft = {
          ...storedDraft,
          tag: data[0].tag, // Preserving the original tag
          team: data[0].team
        };
        setBlueChampionNames([storedNames.blueChampionNames]);
        setRedChampionNames([storedNames.redChampionNames]);
        setDescription(storedNames.description);
        for (let i = 0; i <= 3; i++) {
          data[0][`B${i}`] = newDraft[`B${i}`];
          data[0][`R${i}`] = newDraft[`R${i}`];
        }
        data[0]["description"] = newDraft["description"];
        onDraftChange(data[0]);
      } else {
        console.error('No draft data stored in memory');
      }
    };

    const handlePasteDraft = () => {
      if (storedDraft) {
        setHistory(prevHistory => [...prevHistory, { blueChampionNames, redChampionNames, description }]);
        const newDraft = {
          ...storedDraft,
          tag: data[0].tag, // Preserving the original tag
          team: data[0].team,
          id: data[0].id
        };
        setBlueChampionNames([storedNames.blueChampionNames]);
        setRedChampionNames([storedNames.redChampionNames]);
        setDescription(storedNames.description);
        for (let i = 0; i <= 10; i++) {
          data[0][`B${i}`] = newDraft[`B${i}`];
          data[0][`R${i}`] = newDraft[`R${i}`];
        }
        data[0]["description"] = newDraft["description"];
        onDraftChange(data[0]);

      } else {
        console.error('No draft data stored in memory');
      }
    };

    const handleInputChange = (value, rowIndex, cellIndex, team) => {
      setHistory(prevHistory => [...prevHistory, { blueChampionNames, redChampionNames, description }]);
      const updatedBlueChampionNames = [...blueChampionNames];
      const updatedRedChampionNames = [...redChampionNames];

      if (team === 'blue') {
        updatedBlueChampionNames[cellIndex][rowIndex] = value;
        setBlueChampionNames(updatedBlueChampionNames);
        (data[0][`B${rowIndex}`]) = value;
        const newDraft = {
          [`B${rowIndex}`]: value,  // Use square brackets to compute the key
          id: data[0].id
        };
        onDraftChange(newDraft);
      } else if (team === 'red') {
        updatedRedChampionNames[cellIndex][rowIndex] = value;
        setRedChampionNames(updatedRedChampionNames);
        (data[0][`R${rowIndex}`]) = value;
        const newDraft = {
          [`R${rowIndex}`]: value,  // Use square brackets to compute the key
          id: data[0].id
        };
        onDraftChange(newDraft);
      }

      if (rowIndex ===0 )
      {
        const filteredTeamPropositions = Object.keys(TeamIcons).filter((championName) =>
          championName.toLowerCase().startsWith(value.toLowerCase()) && championName.toLowerCase() !== value.toLowerCase());
        setTeamPropositions(filteredTeamPropositions);
      }
      else
      {
        const filteredPropositions = Object.keys(championPhotos).filter((championName) =>
          championName.toLowerCase().startsWith(value.toLowerCase()) && championName.toLowerCase() !== value.toLowerCase());
        setPropositions(filteredPropositions);
      }

    };

    const handleDescriptionChange = (value) => {
      setHistory(prevHistory => [...prevHistory, { blueChampionNames, redChampionNames, description }]);
      setDescription(value);
      const newDraft = {
        description:  value,
        id: data[0].id
      };
      data[0]["description"] = value;
      onDraftChange(newDraft);
    };

    const handleClearTableButtonClick = () => {
      setHistory(prevHistory => [...prevHistory, { blueChampionNames, redChampionNames, description }]);
      const emptyChampionNames = [new Array(11).fill('')];
      const emptyChampionNames2 = [new Array(11).fill('')];
      setBlueChampionNames(emptyChampionNames);
      setRedChampionNames(emptyChampionNames2);
      for (let i = 0; i <= 10; i++) {
        data[0][`B${i}`] = null;
        data[0][`R${i}`] = null;
      }
      data[0]["team1"] = null
      data[0]["team2"] = null
      setDescription(null)
      data[0].description=null
      onDraftChange(data[0]);
    };

    const handleUndo = () => {
      if (history.length > 0) {
        const previousState = history[history.length - 1];
        setHistory(history.slice(0, -1)); // Remove last state from history

        setBlueChampionNames(previousState.blueChampionNames);
        setRedChampionNames(previousState.redChampionNames);
        setDescription(previousState.description);
        // Update `data` object accordingly
        for (let i = 0; i <= 10; i++) {
          data[0][`B${i}`] = previousState.blueChampionNames[0][i];
          data[0][`R${i}`] = previousState.redChampionNames[0][i];
        }
        data[0]["description"] = previousState.description;
        onDraftChange(data[0]);
      } else {
        toast.warning('No more actions to undo.');
      }
    };

    const handleKeyDown = (e, rowIndex, team, tableIndex) => {
      const maxRows = 10; // Assuming 10 rows as per your map function
      let nextRow = rowIndex;
      let nextTeam = team === "blue" ? "blue" : "red";

      switch (e.key) {
        case "ArrowUp":
          nextRow = Math.max(0, rowIndex - 1);
          break;
        case "ArrowDown":
          nextRow = Math.min(maxRows, rowIndex + 1);
          break;
        case "ArrowLeft":
        case "ArrowRight":
          nextTeam = team === "blue" ? "red" : "blue";
          break;
        default:

          if (e.key === "Tab" && propositions.length > 0) {
            e.preventDefault(); // Prevent the default Tab action
            const firstProposition = propositions[0];
            handleInputChange(firstProposition, rowIndex, 0, team);
          }
          return;
      }
      e.preventDefault();
      const nextInputId = `input-${tableIndex}-${nextTeam}-${nextRow}`;

      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    };

  return (
    <div className="table-container" style={{width: tierlistOpen ? "33%": "25%"}}>
      <table id={tableIndex}>
        <thead style={{borderBottom:  '2px solid black'}} >
          <tr>
            <th colSpan={4} style={{ textAlign: 'center', color: "black", backgroundColor: "white" }} >| Draft {tableIndex+1} | </th>
          </tr>
          <tr>
            <th colSpan={4} style={{ textAlign: 'center', color: "black", backgroundColor: "white" }} >
              <input
                  type="text"
                  value={description || ''}
                  onChange={(e) => handleDescriptionChange(e.target.value)}
                  className="input-field"
                  style={{textAlign: 'center'}}
                /> </th>
          </tr>
          <tr style={{borderBottom:  '2px solid black'}}>
            <th colSpan={1} style={{ textAlign: 'center', color: "black", backgroundColor: "white", borderBottom:  '2px solid black' }} >
              {blueChampionNames[0][0] && TeamIcons.hasOwnProperty(blueChampionNames[0][0]) && (
                <img src={TeamIcons[blueChampionNames[0][0]]} className='small-image' alt=''/>
              )}
            </th>
            <th colSpan={1} style={{ textAlign: 'center', color: "black", backgroundColor: "white", borderBottom:  '2px solid black' }} >
                  <input
                  type="text"
                  value={blueChampionNames[0][0] || ''}
                  onChange={(e) => handleInputChange(e.target.value, 0, 0, 'blue')}
                  className="input-field"
                  style={{textAlign: 'center'}}
                  list={`blue_propositions_team_${0}_${0}`}
                />
                {teamPropositions.length > 0 && (
                  <datalist id={`blue_propositions_team_${0}_${0}`}>
                    {teamPropositions.map((prop, index) => (
                      <option key={index} value={prop} />
                    ))}
                  </datalist>
                )}
            </th>
            <th colSpan={1} style={{ textAlign: 'center', color: "black", backgroundColor: "white", borderBottom:  '2px solid black' }} >
              <input
                type="text"
                value={redChampionNames[0][0] || ''}
                onChange={(e) => handleInputChange(e.target.value, 0, 0, 'red')}
                className="input-field"
                style={{textAlign: 'center'}}
                list={`red_propositions_team_${0}_${0}`}
              />
              {teamPropositions.length > 0 && (
                  <datalist id={`red_propositions_team_${0}_${0}`}>
                    {teamPropositions.map((prop, index) => (
                      <option key={index} value={prop} />
                    ))}
                  </datalist>
                )}
            </th>
            <th colSpan={1} style={{ textAlign: 'center', color: "black", backgroundColor: "white", borderBottom:  '2px solid black' }}>
              {redChampionNames[0][0] && TeamIcons.hasOwnProperty(redChampionNames[0][0]) && (
                <img src={TeamIcons[redChampionNames[0][0]]} className='small-image' alt=''/>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell-img" : "default-cell-img"}>
                {
                  blueChampionNames[0][rowIndex + 1] && blueChampionNames[0][rowIndex + 1].includes('/') &&  blueChampionNames[0][rowIndex + 1].split('/').length === 2? (
                    blueChampionNames[0][rowIndex + 1].split('/').map((name, index) =>
                      championPhotos.hasOwnProperty(name.trim()) && (
                        <img key={index}
                        src={championPhotos[name.trim()]}
                        alt={name.trim()}
                        className="small-image"
                        style={{
                          position: index === 0 ? 'relative' : 'absolute',
                          top: index === 0 ? '0' : '50%',
                          left: index === 0 ? '0' : '50%',
                          maxWidth: "30px",
                          width: '30px',
                          height: '30px',
                          transform: index === 0 ? '' : 'translate(-50%, -50%)', // This centers the second image over the first one
                          clipPath: index === 0 ? 'polygon(0 0, 0% 100%, 100% 0)' : 'polygon(0 100%, 100% 100%, 100% 0)'
                        }}
                      />
                      )
                    )
                  ) : blueChampionNames[0][rowIndex + 1] && blueChampionNames[0][rowIndex + 1].includes('/') &&  blueChampionNames[0][rowIndex + 1].split('/').length === 3? (
                    blueChampionNames[0][rowIndex + 1].split('/').map((name, index) =>
                      championPhotos.hasOwnProperty(name.trim()) && (
                        <img key={index}
                        src={championPhotos[name.trim()]}
                        alt={name.trim()}
                        className="small-image"
                        style={{
                          position: index === 0 ? 'relative' : 'absolute',
                          top: index === 0 ? '0' : '50%',
                          left: index === 0 ? '0' : '50%',
                          maxWidth: "30px",
                          width: '30px',
                          height: '30px',
                          transform: index === 0 ? '' : 'translate(-50%, -50%)', // This centers the second image over the first one
                          clipPath: index === 0 ? 'polygon(50% 0%, 0 0, 0 100%)' : index === 1 ? 'polygon(50% 0%, 100% 100%, 0 100%)' : 'polygon(50% 0%, 100% 100%, 100% 0)',
                        }}
                      />
                      )
                    )
                  ): blueChampionNames[0][rowIndex + 1] && blueChampionNames[0][rowIndex + 1].includes('/') &&  blueChampionNames[0][rowIndex + 1].split('/').length === 4? (
                    blueChampionNames[0][rowIndex + 1].split('/').map((name, index) =>
                      championPhotos.hasOwnProperty(name.trim()) && (
                        <img key={index}
                        src={championPhotos[name.trim()]}
                        alt={name.trim()}
                        className="small-image"
                        style={{
                          position: index === 0 ? 'relative' : 'absolute',
                          top: index === 0 ? '0' : '50%',
                          left: index === 0 ? '0' : '50%',
                          maxWidth: "30px",
                          width: '30px',
                          height: '30px',
                          transform: index === 0 ? '' : 'translate(-50%, -50%)', // This centers the second image over the first one
                          clipPath: index === 0 ? 'polygon(0 0, 50% 0, 50% 50%, 0 50%)' : index === 1 ? 'polygon(0 100%, 50% 100%, 50% 50%, 0 50%)' : index === 2 ? 'polygon(100% 0, 50% 0, 50% 50%, 100% 50%)' : 'polygon(100% 100%, 50% 100%, 50% 50%, 100% 50%)',
                        }}
                      />
                      )
                    )
                  )

                  : championPhotos.hasOwnProperty(blueChampionNames[0][rowIndex + 1]) && (
                    <img src={championPhotos[blueChampionNames[0][rowIndex+1]]}
                      alt={""}
                      className="small-image" />
                  )
                }
              </td>

              <td style={{borderBottom: rowIndex === 3 ? '4px solid black' : 'none'}} className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell" : "default-cell"}>
              <input
                type="text"
                value={blueChampionNames[0][rowIndex+1] || ''}
                onChange={(e) => handleInputChange(e.target.value, rowIndex+1, 0, 'blue')}
                onKeyDown={(e) => handleKeyDown(e, rowIndex+1, 'blue', tableIndex)}
                list={`blue_propositions_${tableIndex}_${rowIndex}`}
                className="input-field"
                id={`input-${tableIndex}-blue-${rowIndex+1}`}
              />
                {propositions.length > 0 && (
                  <datalist id={`blue_propositions_${tableIndex}_${rowIndex}`}>
                    {propositions.map((prop, index) => (
                      <option key={index} value={prop} />
                    ))}
                  </datalist>
                )}
              </td>
              <td style={{borderBottom: rowIndex === 4 || rowIndex === 8  ? '4px solid black' : 'none'}} className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell"}>
              <input
                type="text"
                value={redChampionNames[0][rowIndex+1] || ''}
                onChange={(e) => handleInputChange(e.target.value, rowIndex+1, 0, 'red')}
                onKeyDown={(e) => handleKeyDown(e, rowIndex+1, 'red', tableIndex)}
                list={`red_propositions_${tableIndex}_${rowIndex}`}
                className="input-field"
                id={`input-${tableIndex}-red-${rowIndex+1}`}
              />
                {propositions.length > 0 && (
                  <datalist id={`red_propositions_${tableIndex}_${rowIndex}`}>
                    {propositions.map((prop, index) => (
                      <option key={index} value={prop} />
                    ))}
                  </datalist>
                )}
              </td>
              <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell-img" : "default-cell-img"}>
              {
                  redChampionNames[0][rowIndex + 1] && redChampionNames[0][rowIndex + 1].includes('/') &&  redChampionNames[0][rowIndex + 1].split('/').length === 2? (
                    redChampionNames[0][rowIndex + 1].split('/').map((name, index) =>
                      championPhotos.hasOwnProperty(name.trim()) && (
                        <img key={index}
                        src={championPhotos[name.trim()]}
                        alt={name.trim()}
                        className="small-image"
                        style={{
                          position: index === 0 ? 'relative' : 'absolute',
                          top: index === 0 ? '0' : '50%',
                          left: index === 0 ? '0' : '50%',
                          maxWidth: "30px",
                          width: '30px',
                          height: '30px',
                          transform: index === 0 ? '' : 'translate(-50%, -50%)', // This centers the second image over the first one
                          clipPath: index === 0 ? 'polygon(0 0, 0% 100%, 100% 0)' : 'polygon(0 100%, 100% 100%, 100% 0)'
                        }}
                      />
                      )
                    )
                  ) : redChampionNames[0][rowIndex + 1] && redChampionNames[0][rowIndex + 1].includes('/') &&  redChampionNames[0][rowIndex + 1].split('/').length === 3? (
                    redChampionNames[0][rowIndex + 1].split('/').map((name, index) =>
                      championPhotos.hasOwnProperty(name.trim()) && (
                        <img key={index}
                        src={championPhotos[name.trim()]}
                        alt={name.trim()}
                        className="small-image"
                        style={{
                          position: index === 0 ? 'relative' : 'absolute',
                          top: index === 0 ? '0' : '50%',
                          left: index === 0 ? '0' : '50%',
                          maxWidth: "30px",
                          width: '30px',
                          height: '30px',
                          transform: index === 0 ? '' : 'translate(-50%, -50%)', // This centers the second image over the first one
                          clipPath: index === 0 ? 'polygon(50% 0%, 0 0, 0 100%)' : index === 1 ? 'polygon(50% 0%, 100% 100%, 0 100%)' : 'polygon(50% 0%, 100% 100%, 100% 0)',
                        }}
                      />
                      )
                    )
                  ): redChampionNames[0][rowIndex + 1] && redChampionNames[0][rowIndex + 1].includes('/') &&  redChampionNames[0][rowIndex + 1].split('/').length === 4? (
                    redChampionNames[0][rowIndex + 1].split('/').map((name, index) =>
                      championPhotos.hasOwnProperty(name.trim()) && (
                        <img key={index}
                        src={championPhotos[name.trim()]}
                        alt={name.trim()}
                        className="small-image"
                        style={{
                          position: index === 0 ? 'relative' : 'absolute',
                          top: index === 0 ? '0' : '50%',
                          left: index === 0 ? '0' : '50%',
                          maxWidth: "30px",
                          width: '30px',
                          height: '30px',
                          transform: index === 0 ? '' : 'translate(-50%, -50%)', // This centers the second image over the first one
                          clipPath: index === 0 ? 'polygon(0 0, 50% 0, 50% 50%, 0 50%)' : index === 1 ? 'polygon(0 100%, 50% 100%, 50% 50%, 0 50%)' : index === 2 ? 'polygon(100% 0, 50% 0, 50% 50%, 100% 50%)' : 'polygon(100% 100%, 50% 100%, 50% 50%, 100% 50%)',
                        }}
                      />
                      )
                    )
                  )

                  : championPhotos.hasOwnProperty(redChampionNames[0][rowIndex + 1]) && (
                    <img src={championPhotos[redChampionNames[0][rowIndex+1]]}
                      alt={""}
                      className="small-image" />
                  )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{display: 'flex', width: '100%',justifyContent: 'space-between'}}>
        <button className='xd' style={{width: "20%"}}onClick={handleClearTableButtonClick}>Clear</button>
        <button className='xd' style={{width: "20%"}} onClick={handleCopyDraft}>Copy</button>
        <button className='xd' style={{width: "10%",  padding: "0", display: "flex", alignItems: "center", justifyContent: "space-around"}} onClick={handleUndo}>⬅</button>
        <button className='xd' style={{width: "10%",  padding: "0", display: "flex", alignItems: "center", justifyContent: "space-around"}} onClick={() => captureScreenshot(tableIndex)}>⬇</button>
        <button className='xd' style={{width: "20%"}} onClick={handlePasteDraft}>Paste</button>
        <button className='xd' style={{width: "20%"}} onClick={handlePasteBans}>Bans</button>
      </div>
    </div>
  );
});

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const MultiTable = () => {
  document.title = "Drafts";

  const Spreadsheet = {
    "SUP": "https://docs.google.com/spreadsheets/d/1luXguJEl1lK-h2QyP6EyBwTFET90f1KDzGb9Vruu4WU/edit?usp=sharing",
    "FAK": "https://docs.google.com/spreadsheets/d/1Cpy8sGTRTOv7rfdZgtXGtewy8DKPzygXtS9PBjJK0Wg/edit?usp=sharing",
    "FAKA": "https://docs.google.com/spreadsheets/d/1w9M9hJeD7k7YB-t9Ubn8o5JQPaYIAOpOi6XKzmv9anY/edit?usp=sharing",
    "PG": "https://docs.google.com/spreadsheets/d/1w9M9hJeD7k7YB-t9Ubn8o5JQPaYIAOpOi6XKzmv9anY/edit?usp=sharing",

  };

  const tierColors = {
    S: 'rgb(255, 127, 128)', // Gold
    A: 'rgb(255, 192, 127)', // Silver
    B: 'rgb(255, 255, 127)', // Bronze
    C: 'rgb(191, 255, 127)', // Light Blue
    D: 'rgb(127, 255, 127)', // Light Gray
  };

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTag, setSelectedTag] = useState('scrims');
  const [changedDrafts, setChangedDrafts] = useState([]);
  const [storedDraft, setStoredDraft] = useState(null);
  const [storedNames, setStoredNames] = useState(null);
  const [tierlistData, setTierlistData] = useState(null);
  const [tierlistOpen, setTierlistOpen] = useState(false);
  const [selectedTierlist, setSelectedTierlist] = useState(null);
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [isScrollLocked, setIsScrollLocked] = useState(false);

  const [socket, setSocket] = useState(null);
  const socketRef = useRef(null);

  const tableRefs = useRef([]);

  useEffect(() => {
    tableRefs.current = Array(20).fill().map((_, i) => tableRefs.current[i] || createRef());
  }, []);

  useEffect(() => {
    const connectWebSocket = () => {
      const ws = new WebSocket(
        'wss://sanchidataportal.com/ws/ws/some_path/' +
          localStorage.getItem('team') +
          '/'
      );
      setSocket(ws);
      socketRef.current = ws;
      ws.onmessage = (event) => {
        try {
          const updatedData = JSON.parse(event.data);
          setData((prevData) =>
            prevData.map((item) =>
              item.id === updatedData.id ? { ...item, ...updatedData } : item
            )
          );
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = (event) => {
        setTimeout(connectWebSocket, 3000);
      };
    };

    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);
  useEffect(() => {
    if (isScrollLocked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isScrollLocked]);

  const handleTagButtonClick = (tag) => {
    setSelectedTag(tag);
  };

  const handleDraftUpdate = (updatedDraft) => {
    if (!changedDrafts.includes(updatedDraft.tag)) {
      setChangedDrafts([...changedDrafts, updatedDraft.tag]);
    }

    if (socket) {
      try {
        socket.send(JSON.stringify(updatedDraft));
      } catch (error) {
        console.error('Error sending WebSocket message:', error);
      }
    }
  };


  const sendDebouncedMessage = useCallback(
    debounce((message) => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(message));
      }
    }, 300),
    []
  );

  const handleClearAllTables = () => {
    tableRefs.current.forEach(ref => {
      if (ref.current) {
        ref.current.clearTable();
      }
    });
  };

  const toggleTierlistVisibility = () => {
    setTierlistOpen(!tierlistOpen);
    setSelectedTierlist(tierlistData.tiers);
  };

  const toggleIframeVisibility = () => {
    setIsIframeVisible(!isIframeVisible);
  };

  useEffect(() => {
    // Fetch initial data from the REST API
    const fetchData = async () => {
      try {
        const draftResponse = await axios.get(`https://sanchidataportal.com/api/drafts/?team=${localStorage.getItem("team")}`);
        setData(draftResponse.data);
        setIsLoading(false);

        const tierlistResponse = await axios.get(`https://sanchidataportal.com/api2/api/tierlist/${localStorage.getItem("team")}`);
        setTierlistData(tierlistResponse.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div style={{ position: 'relative', minHeight: '100vh', overflow: isScrollLocked ? 'hidden' : 'auto' }}>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="button-container">
        <div className="left-button-container">
        </div>
        <div style={{ alignItems: 'center', color: 'white' }}>
          {selectedTag.toLocaleUpperCase()}
        </div>
        <div className="right-button-container">
          {['scrims', 'game1', 'game2', 'game3', 'game4', 'game5'].map(tag => (
            <button key={tag} className='xd' onClick={() => handleTagButtonClick(tag)}>{tag.toUpperCase()}</button>
          ))}
          <button className='xd' onClick={toggleTierlistVisibility}>TierList</button>
          <button className='xd' onClick={toggleIframeVisibility}>{isIframeVisible ? 'Notes' : ' Notes'}</button>
        </div>
      </div>
      <div style={{width: "100%", display: "flex"}}>


        <div className='tables-container'  style={{width: tierlistOpen ? "75%": "100%", }}>
          {[...Array(20)].map((_, index) => {
            tableRefs.current[index] = React.createRef(); // Initialize the ref
            const filteredData = data.filter((draft) => {
              const [tag, draftNumber] = draft.tag.split(' ');
              return tag === selectedTag && parseInt(draftNumber, 10) === index + 1;
            });
            return <Table key={index} ref={tableRefs.current[index]} data={filteredData} tableIndex={index} onDraftChange={sendDebouncedMessage} storedNames={storedNames} setStoredNames={setStoredNames} storedDraft={storedDraft} setStoredDraft={setStoredDraft} tierlistOpen={tierlistOpen} />;
          })}
        </div>
        <div style={{width: tierlistOpen ? "23%": "0%", height: "90vh", overflowY: "auto", position: "fixed",  right: 0,}}>
          {tierlistOpen && selectedTierlist && (
            <div style={{ backgroundColor: "black", color: 'white'}}>
              <h2>TierList</h2>
              {Object.keys(selectedTierlist).map((role, index) => (
                <div key={role}>

                  <h3 style={{textAlign: "center"}}><img style={{width: "30px"}}src={roleIcon[index]} alt={role}/>{role.toUpperCase()}</h3>
                  {Object.keys(selectedTierlist[role]).map(tier => (
                    tier !== 'pool' && selectedTierlist[role][tier].length >0  && (
                      <div key={tier} style={{ backgroundColor: tierColors[tier] || '#FFFFFF'}}>
                        <h4 style={{color: 'black'}}>{tier}</h4>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {selectedTierlist[role][tier].map(champion => (
                            <img
                              key={champion}
                              src={championPhotos[championData[champion]]}
                              alt={champion}
                              style={{ width: '50px', height: '50px' }}
                            />
                          ))}
                        </div>
                      </div>
                    )
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "100%", alignItems: "center", display: "flex",justifyContent: 'center' }}>
        <button className='xd' onClick={handleClearAllTables}>Clear All Drafts</button>
      </div>
      {isIframeVisible && (
        <>
          <div style={{ position: 'relative', minHeight: '40vh' }} />
          <div className="iframe-container">
            <iframe
              title='Google Sheets'
              className="iframe-clipped"
              src={Spreadsheet[localStorage.getItem("team")]}
              onMouseEnter={() => setIsScrollLocked(true)}
              onMouseLeave={() => setIsScrollLocked(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MultiTable;

