import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import roleIcon from './Role'; // Adjust the path accordingly
import './Player.css';
import { championPhotos } from "./ChampionsIcon";
import CustomSelect from './CustomSelect';
import 'react-pivottable/pivottable.css';
import TeamIcons from './TeamIcons';
import SoloqPageComponent from './SoloqPage';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';
import ChampionPerformanceComponent from './PlayerChampionsPerformance';
import ProximityPlayerComponent from './ProximityPlayer';
import { useSeason } from './SeasonContext';
import iconDictionary from './Icons';
import HeatmapComponent from './WardHeatmap';


const PlayerPage = () => {
  const { currentSeasonTime } = useSeason();
  const { name } = useParams();
  document.title = name;
  const [playerData, setPlayerData] = useState([]);
  const [filterPlayerData, setFilterPlayerData] = useState([]);
  const [proximityData, setProximityData] = useState([]);
  const [filteredProximityData, setFilteredProximityData] = useState([]);

  const [wardData, setWardData] = useState([]);
  const [teamMatesData, setTeamMatesData] = useState([]);
  const encodedPlayerName = encodeURIComponent(name);

  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [startDate, setStartDate] = useState(new Date('2024-05-14'));
  const [endDate, setEndDate] = useState(new Date());

  const [championDictionary, setChampionDictionary] = useState({});
  const [championDataDictionary, setChampionDataDictionary] = useState({});
  const [playerPhoto, setPlayerPhoto] = useState()
  const [playerPercentile, setPlayerPercentile] = useState(null);

  const captureScreenshot = (table) => {
    // Select the element you want to screenshot
    const element = document.getElementById(table);

    html2canvas(element)
      .then((canvas) => {
        // Create an image
        const image = canvas.toDataURL('image/png');

        // You can now use this image for your presentation
        // For example, download it as an image file
        const link = document.createElement('a');
        link.download = 'screenshot.png';
        link.href = image;
        link.click();
      })
      .catch(err => {
        console.error('Error capturing screenshot', err);
      });
  };

  const getPlayerNameWithoutTeamTag = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' ');
  };


  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axios
      .get(`https://sanchidataportal.com/api/player/?name=${encodedPlayerName}&start=${start}&end=${end}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        const uniquePatches = [...new Set(sortedData.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        setPlayerData(sortedData);
      })
      .catch(error => {
        console.error(error);
      });

      axios
      .get(`https://sanchidataportal.com/api/proximity/?name=${encodedPlayerName}&start=${start}&end=${end}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setProximityData(sortedData);
      })
      .catch(error => {
        console.error(error);
      });

      axios
      .get(`https://sanchidataportal.com/api/player_percentile/?name=${encodedPlayerName}&start=${start}&end=${end}`)
      .then(response => {
        setPlayerPercentile(response.data);
      })
      .catch(error => {
        console.error(error);
      });

      axios
      .get(`https://sanchidataportal.com/api/playerwards_official/?name=${encodedPlayerName}&start=${start}&end=${end}`)
      .then(response => {
        setWardData(response.data);
      })
      .catch(error => {
        console.error(error);
      });


      axios.get(`https://sanchidataportal.com/api/playerphoto/?player_name=${(getPlayerNameWithoutTeamTag(name))}`)
      .then(response => {
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          setPlayerPhoto(response.data[0].link);
        } else {
          setPlayerPhoto(iconDictionary["human"]);
        }
      })
      .catch(error => {
        console.error(error);
      });

    }, [encodedPlayerName, currentSeasonTime]);

  useEffect(() => {
      const teamName = name.split(" ")[0];
      const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
      axios.get(`https://sanchidataportal.com/api/teammates/?name=${encodeURIComponent(teamName)}`+ '&start=' + start + "&end=" + end)
        .then(response => {
          // Update the team mates data state
          const sortedData = response.data.sort((a, b) => a.Role - b.Role);
          setTeamMatesData(sortedData);
        })
        .catch(error => {
          console.error(error);
        });

   },  [name, currentSeasonTime]);


    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          return isPatchGood;
        });

        const newChampionDictionary = {};

        // Iterate through the filtered player data to update the champion dictionary
        filteredPlayersPick.forEach((player) => {
          const championName = player.championName;

          if (!newChampionDictionary[championName]) {
            newChampionDictionary[championName] = {
              matchesPlayed: 0,
              totalWins: 0,
            };
          }

          newChampionDictionary[championName].matchesPlayed += 1;
          newChampionDictionary[championName].totalWins += player.winner;
        });

        // Calculate win rates and sort the champion data
        for (const championName in newChampionDictionary) {
          const champion = newChampionDictionary[championName];
          champion.winRate = ((champion.totalWins * 100) / champion.matchesPlayed).toFixed(2);
        }

        const sortedChampions = Object.entries(newChampionDictionary).sort(
          (a, b) => b[1].matchesPlayed - a[1].matchesPlayed
        );

        // Map the sorted entries back to an object
        const sortedChampionObject = Object.fromEntries(sortedChampions);

        setFilterPlayerData(filteredPlayersPick);
        setChampionDictionary(sortedChampionObject);
      }
    }, [playerData, filterPatch]);

    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          return isPatchGood;
        });
        const newChampionDictionary = {};

        filteredPlayersPick.forEach((player) => {
          const championName = player.championName;

          if (!newChampionDictionary[championName]) {
            newChampionDictionary[championName] = {
              matchesPlayed: 0,
              totalWins: 0,
              // Add more properties for the columns you want to calculate
              // For example:
              killsAndAssists8: 0,
              deaths8: 0,
              goldDiff8: 0,
              csDiff8: 0,
              expDiff8: 0,
              killsAndAssists14: 0,
              deaths14: 0,
              goldDiff14: 0,
              csDiff14: 0,
              expDiff14: 0,
              damageShared: 0,
              goldShared: 0,
              JungleProximity: 0,
              kills: 0,
              deaths: 0,
              assists: 0,
              DamageDealt: 0,
              GoldEarned: 0,
              CS: 0,
              // Add more properties as needed
            };
          }

          newChampionDictionary[championName].matchesPlayed += 1;
          newChampionDictionary[championName].totalWins += player.winner;
          // Update other properties for the columns you want to calculate
          // For example:
          newChampionDictionary[championName].killsAndAssists8 += player.killsAndAssists8;
          newChampionDictionary[championName].deaths8 += player.deaths8;
          newChampionDictionary[championName].goldDiff8 += player.goldDiff8;
          newChampionDictionary[championName].csDiff8 += player.csDiff8;
          newChampionDictionary[championName].expDiff8 += player.expDiff8;
          newChampionDictionary[championName].killsAndAssists14 += player.killsAndAssists14;
          newChampionDictionary[championName].deaths14 += player.deaths14;
          newChampionDictionary[championName].goldDiff14 += player.goldDiff14;
          newChampionDictionary[championName].csDiff14 += player.csDiff14;
          newChampionDictionary[championName].expDiff14 += player.expDiff14;
          newChampionDictionary[championName].damageShared += player.damageShared;
          newChampionDictionary[championName].goldShared += player.goldShared;
          newChampionDictionary[championName].JungleProximity += player.JungleProximity;
          newChampionDictionary[championName].kills += player.kills;
          newChampionDictionary[championName].deaths += player.deaths;
          newChampionDictionary[championName].assists += player.assists;
          newChampionDictionary[championName].DamageDealt += player.DamageDealt;
          newChampionDictionary[championName].GoldEarned += player.GoldEarned;
          newChampionDictionary[championName].CS += player.CS;
          // Update other properties as needed
        });

        // Calculate win rates for each champion
        for (const championName in newChampionDictionary) {
          const champion = newChampionDictionary[championName];
          champion.winRate = ((champion.totalWins * 100) / champion.matchesPlayed).toFixed(2);
        }

        setChampionDataDictionary(newChampionDictionary);
      }
      if (proximityData) {
        const filteredProximityData1 = proximityData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          return isPatchGood;
        });
        setFilteredProximityData(filteredProximityData1);
      }
    }, [playerData, filterPatch, proximityData]);

    const handleStartDateChange = (e) => {
      const selectedDate = new Date(e.target.value);
      selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
      setStartDate(selectedDate);
    };

    const handleEndDateChange = (e) => {
      const selectedDate = new Date(e.target.value);
      selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
      setEndDate(selectedDate);
    };


  const handlePatchChange = (selectedOptions) => {
    //setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleClick = (teamName) => {
    const searchQuery = encodeURIComponent(teamName); // Encode the value for URL
    window.location.href = `/playerscrim/${searchQuery}`;
  };

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // If the same column is clicked, reverse the sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different column is clicked, set it as the sorted column and default to ascending order
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const compareValues = (key, order = 'asc') => {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // Property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  };

  function averageValue(columnName) {
    const total = filterPlayerData.reduce((acc, record) => acc + record[columnName], 0);

    return parseFloat((total / filterPlayerData.length).toFixed(2)); // You can adjust the number of decimal places
  }

  function averageRatio(columnName1, columnName2) {
    const totalRatio = filterPlayerData.reduce((acc, record) => {
      const ratio = record[columnName2] ? record[columnName1] / record[columnName2] : 0;
      return acc + ratio;
    }, 0);

    return parseFloat((totalRatio / filterPlayerData.length).toFixed(2)); // Ensure it returns a number
  }

  function calculateAverageDamagePerMinute(player) {
    return (player.DamageDealt / player.gameDuration).toFixed(0);
  }

  function calculateAverageCSPerMinute(player) {
    return (player.CS / player.gameDuration).toFixed(2);
  }

  function calculateAverageGoldPerMinute(player) {
    return (player.GoldEarned / player.gameDuration).toFixed(0);
  }

  const sortedFilterPlayerData = filterPlayerData.slice().sort((a, b) => {
    a.averageCSPerMinute = calculateAverageCSPerMinute(a);
    b.averageCSPerMinute = calculateAverageCSPerMinute(b);

    a.averageGoldPerMinute = calculateAverageGoldPerMinute(a);
    b.averageGoldPerMinute = calculateAverageGoldPerMinute(b);
    if (sortedColumn === 'damageShared' || sortedColumn === 'goldShared' || sortedColumn === 'JungleProximity') {
      // Handle sorting for percentage columns
      const aValue = a[sortedColumn] * 100;
      const bValue = b[sortedColumn] * 100;
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (sortedColumn === 'DamageDealt')
    {
      const aValue = calculateAverageDamagePerMinute(a);
      const bValue = calculateAverageDamagePerMinute(b);
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }
    else if (sortedColumn === 'CS') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageCSPerMinute) - parseFloat(b.averageCSPerMinute)
      : parseFloat(b.averageCSPerMinute) - parseFloat(a.averageCSPerMinute);
    } else if (sortedColumn === 'GoldEarned') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageGoldPerMinute) - parseFloat(b.averageGoldPerMinute)
      : parseFloat(b.averageGoldPerMinute) - parseFloat(a.averageGoldPerMinute);
   }
   else if (sortedColumn === 'championName' || sortedColumn === 'enemyChampionName') {

      const aValue = a[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const bValue = b[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    else
    {
      // Handle sorting for other columns
      return sortDirection === 'asc'
        ? a[sortedColumn] - b[sortedColumn]
        : b[sortedColumn] - a[sortedColumn];
    }
  });


  const getMaxValue = (property) => {
    return Math.max(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const getMaxValueDivided = (property, divisor) => {
    return Math.max(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };

  const getMinValueDivided = (property, divisor) => {
    return Math.min(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };

  const renderPercentileBar = (label, percentile) => (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: "100%" }}>
      <div style={{ flex: 3, backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden', position: 'relative' }}>
        <div style={{
          width: `${percentile}%`,
          backgroundColor: '#ff7f50',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'right',
        }}>
          <span style={{ color: 'black', fontWeight: 'bold', }}>{percentile}</span>
        </div>
      </div>
    </div>
  );

  const getRedCellColor = (value, maxValue) => {
    const normalizedValue = value / maxValue;
    const hue = 0; // Hue value (green)
    const saturation = 100; // Saturation value (100%)
    const minLightness = 100; // Minimum Lightness value (100%)
    const maxLightness = 50; // Maximum Lightness value (50%)

    const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  };

  const getGreenCellColor = (value, maxValue) => {
    const normalizedValue = value / maxValue;
    const hue = 128; // Hue value (green)
    const saturation = 100; // Saturation value (100%)
    const minLightness = 100; // Minimum Lightness value (100%)
    const maxLightness = 50; // Maximum Lightness value (50%)

    const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  };

  const getRedGreenCell = (value, minValue, maxValue) => {
    if (value === 0) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const normalizedValue = value > 0 ? Math.abs(value) / maxValue : Math.abs(value / minValue); // Use absolute value

      // Define hue for the color gradient (e.g., green or red)
      const hue = value > 0 ? 120 : 0; // Green for positive, Red for negative

      // Interpolate lightness between 100% and 50% based on normalized value
      const lightness = 100 - 50 * normalizedValue;

      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  const getRedGreenCellno0 = (value, minValue, maxValue) => {
    if (value === (minValue + maxValue) / 2) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const midpoint = (minValue + maxValue) / 2;
      const normalizedValue =
        value > midpoint
          ? (value - midpoint) / (maxValue - midpoint)
          : (-value + midpoint) / (minValue + midpoint);

      // Define hue for the color gradient (e.g., green or red)
      const hue = value > midpoint ? 120 : 0; // Green for positive, Red for negative

      // Interpolate lightness between 100% and 50% based on normalized value
      const lightness = 100 - 50 * normalizedValue;

      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  const handleGameClick = (teamName) => {
    window.location.href = `/match/${teamName}`;
  };

  const handleClickTeam = (teamName) => {
    window.location.href = `/team/${teamName}`;
  };

  return (
    <div className='main'>
      <div style={{width:"100%", textAlign: "center", color: "white"}}>
      Officials
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', color: "black", width: "100%", backgroundColor: "white", marginBottom: "10px", alignItems: "center", textAlign: "center", borderBottom: "2px solid rgb(0, 242, 255)" }}>
            <button onClick={() => handleClickTeam(name.split(" ")[0])}>Go To <img className='small-image' src={TeamIcons[name.split(" ")[0]]} alt="" />{name.split(" ")[0]}</button>
            <div>
              {name}
                <img className='small-image' src={TeamIcons[name.split(" ")[0]]} alt="" />
            </div>
            <button onClick={() => handleClick(name)}>GO TO SCRIMS</button>
      </div>
      <div style={{ alignItems: "center" }}>
        {teamMatesData && (
          <div className="teammates-container">
            <div style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}  >
              {teamMatesData.map(teammate => (
                <a
                  key={teammate.summonerName}
                  href={`/player/${encodeURIComponent(teammate.summonerName)}`}
                  className="teammate-link-2"
                >
                  <div className="teammate">
                    <img
                      src={roleIcon[teammate.Role]}
                      alt={`Role ${teammate.Role} Icon`}
                      className="role-icon"
                    />
                    <span className="teammate-name-2">{teammate.summonerName}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>

      {playerPercentile ? (
        <div style={{ display: "flex", width: "100%", backgroundColor: "#20232a", color: "#61dafb", padding: "20px" }}>
          <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
            <div style={{ fontSize: "2em", fontWeight: "bold" }}>{name}</div>
            <img
              src={playerPhoto} // Use the fetched photo or fallback
              alt={name}
              style={{
                width: "100px",     // Set a fixed width
                height: "100px",    // Set a fixed height
                objectFit: "cover",
              }}
            />
            <div style={{ fontSize: "1em", color: "white"}}>The higher number the better. It represents in which percentile the player is based on all players performance in the database (Players page)</div>
          </div>
          <div style={{ width: "70%", padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{width: "20%", textAlign: "center", marginRight: "20px" }}>
                <div style={{ fontSize: "2em", fontWeight: "bold" }}>Econ</div>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>GD+XPD@8</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{(averageValue('goldDiff8') + averageValue('expDiff8')).toFixed(0)}</div>
                </div>
                {renderPercentileBar('GD + EPXD at 8', playerPercentile["percentiles"]['GD+EPXD_8'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>GD+XPD@14</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{(averageValue('goldDiff14').valueOf() + averageValue('expDiff14').valueOf()).toFixed(0)}</div>
                </div>
                {renderPercentileBar('GD + EPXD at 14', playerPercentile["percentiles"]['GD+EPXD_14'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>GD/M</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{(averageValue('GoldEarned') / averageValue('gameDuration')).toFixed(0)}</div>
                </div>
                {renderPercentileBar('GD/M', playerPercentile["percentiles"]['GD_per_Minute'])}

              </div>
              <div style={{width: "20%", textAlign: "center", marginRight: "20px" }}>
                <div style={{ fontSize: "2em", fontWeight: "bold" }}>Combat</div>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>DMG R at 8</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageRatio('damageDealt8', 'damageReceived8').toFixed(2)}</div>
                </div>
                {renderPercentileBar('Damage Ratio at 8', playerPercentile["percentiles"]['Damage_Ratio_8'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>DMG R at 14</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageRatio('damageDealt14', 'damageReceived14').toFixed(2)}</div>
                </div>
                {renderPercentileBar('Damage Ratio at 14', playerPercentile["percentiles"]['Damage_Ratio_14'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>DMG/M</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{(averageValue('DamageDealt') / averageValue('gameDuration')).toFixed(0)}</div>
                </div>
                {renderPercentileBar('DMG/M', playerPercentile["percentiles"]['DMG_per_Minute'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>DMG/GD</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{(averageValue('DamageDealt') / averageValue('GoldEarned')).toFixed(2)}</div>
                </div>
                {renderPercentileBar('DMG/GD', playerPercentile["percentiles"]['DMG_per_GD'])}
              </div>
              <div style={{width: "20%", textAlign: "center", marginRight: "20px" }}>
                <div style={{ fontSize: "2em", fontWeight: "bold" }}>K+A</div>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>K+A at 8</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageValue('killsAndAssists8').toFixed(0)}</div>
                </div>
                {renderPercentileBar('K+A at 8', playerPercentile["percentiles"]['Kills_Assists_8'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>K+A at 14</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageValue('killsAndAssists14').toFixed(0)}</div>
                </div>
                {renderPercentileBar('K+A at 14', playerPercentile["percentiles"]['Kills_Assists_14'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>K+A</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{(averageValue('kills') + averageValue('assists')).toFixed(0)}</div>
                </div>
                {renderPercentileBar('Total K+A', playerPercentile["percentiles"]['Total_Kills_Assists'])}
              </div>
              <div style={{width: "20%", textAlign: "center", marginRight: "20px" }}>
                <div style={{ fontSize: "2em", fontWeight: "bold" }}>CSD</div>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>CSD at 8</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageValue('csDiff8').toFixed(0)}</div>
                </div>
                {renderPercentileBar('CSD at 8', playerPercentile["percentiles"]['CSD_8'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>CSD at 14</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageValue('csDiff14').toFixed(0)}</div>
                </div>
                {renderPercentileBar('CSD at 14', playerPercentile["percentiles"]['CSD_14'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>CS/M</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{(averageValue('CS') / averageValue('gameDuration')).toFixed(2)}</div>
                </div>
                {renderPercentileBar('CS/M', playerPercentile["percentiles"]['CS_per_Minute'])}
              </div>
              <div style={{width: "20%", textAlign: "center", marginRight: "20px" }}>
                <div style={{ fontSize: "2em", fontWeight: "bold" }}>Deaths</div>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>Deaths at 8</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageValue('deaths8').toFixed(0)}</div>
                </div>
                {renderPercentileBar('Deaths at 8', 100 - playerPercentile["percentiles"]['Deaths_8'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>Deaths at 14</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageValue('deaths14').toFixed(0)}</div>
                </div>
                {renderPercentileBar('Deaths at 14', 100 - playerPercentile["percentiles"]['Deaths_14'])}
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1.2em", color: "white" }}>Deaths</div>
                  <div style={{ fontSize: "1.2em", color: "white" }}>{averageValue('deaths').toFixed(0)}</div>
                </div>
                {renderPercentileBar('Total Deaths', 100 - playerPercentile["percentiles"]['Total_Deaths'])}
              </div>
            </div>
          </div>
        </div>
      ):(
        <>
          xd
        </>
      )}

      <div className='filters'>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
      </div>
      <div className='team-perfromance-by-game' style={{width: "100%", color: "black"}}>
        {filterPlayerData ? (

          <table className='team-perfromance-by-game-table'>
            <thead>
              <tr>
                <th onClick={() => handleSort('championName')}>championName</th>
                <th onClick={() => handleSort('enemyChampionName')}>enemyName</th>
                <th onClick={() => handleSort('winner')}>WR%</th>
                <th onClick={() => handleSort('killsAndAssists8')}>K&A@8</th>
                <th onClick={() => handleSort('deaths8')}>D@8</th>
                <th onClick={() => handleSort('goldDiff8')}>GD@8</th>
                <th onClick={() => handleSort('csDiff8')}>CSD@8</th>
                <th onClick={() => handleSort('expDiff8')}>XPD@8</th>
                <th onClick={() => handleSort('killsAndAssists14')}>K&A@14</th>
                <th onClick={() => handleSort('deaths14')}>D@14</th>
                <th onClick={() => handleSort('goldDiff14')}>GD@14</th>
                <th onClick={() => handleSort('csDiff14')}>CSD@14</th>
                <th onClick={() => handleSort('expDiff14')}>XPD@14</th>
                <th onClick={() => handleSort('damageShared')}>DMG%</th>
                <th onClick={() => handleSort('goldShared')}>G%</th>
                <th onClick={() => handleSort('JungleProximity')}>JGL Prox</th>
                <th onClick={() => handleSort('kills')}>K</th>
                <th onClick={() => handleSort('deaths')}>D</th>
                <th onClick={() => handleSort('assists')}>A</th>
                <th onClick={() => handleSort('DamageDealt')}>DMG/M</th>
                <th onClick={() => handleSort('GoldEarned')}>G/M</th>
                <th onClick={() => handleSort('CS')}>CS/M</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedFilterPlayerData.map((record, index) => {

                  const damagePerMinute = (record.DamageDealt / record.gameDuration).toFixed(0);
                  const csPerMinute = (record.CS / record.gameDuration).toFixed(2);
                  const goldPerMinute = (record.GoldEarned / record.gameDuration).toFixed(0);
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left', justifyContent: 'left' }}><img className='small-image' src={championPhotos[record.championName]} alt="" />{record.championName}</td>
                    <td style={{ textAlign: 'left' }}><img className='small-image' src={championPhotos[record.enemyChampionName]} alt="" />{record.enemyChampionName}</td>
                    <td>{record.winner ? 'Yes' : 'No'}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists8, getMaxValue('killsAndAssists8')) }}>{record.killsAndAssists8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths8, getMaxValue('deaths8')) }}>{record.deaths8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff8, getMinValue('goldDiff8'), getMaxValue('goldDiff8'))}}>{record.goldDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff8, getMinValue('csDiff8'), getMaxValue('csDiff8'))}}>{record.csDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff8, getMinValue('expDiff8'), getMaxValue('expDiff8'))}}>{record.expDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists14, getMaxValue('killsAndAssists14')) }}>{record.killsAndAssists14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths14, getMaxValue('deaths14')) }}>{record.deaths14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff14, getMinValue('goldDiff14'), getMaxValue('goldDiff14'))}}>{record.goldDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff14, getMinValue('csDiff14'), getMaxValue('csDiff14'))}}>{record.csDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff14, getMinValue('expDiff14'), getMaxValue('expDiff14'))}}>{record.expDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.damageShared, getMinValue('damageShared'), getMaxValue('damageShared'))}}>{(record.damageShared*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.goldShared, getMinValue('goldShared'), getMaxValue('goldShared'))}}>{(record.goldShared*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.JungleProximity, getMinValue('JungleProximity'), getMaxValue('JungleProximity'))}}>{(record.JungleProximity*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.kills, getMaxValue('kills')) }}>{record.kills}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths, getMaxValue('deaths')) }}>{record.deaths}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.assists, getMaxValue('assists')) }}>{record.assists}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(damagePerMinute, getMinValueDivided('DamageDealt','gameDuration'), getMaxValueDivided('DamageDealt','gameDuration'))}}>{(damagePerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(goldPerMinute, getMinValueDivided('GoldEarned', 'gameDuration'), getMaxValueDivided('GoldEarned', 'gameDuration'))}}>{(goldPerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(csPerMinute, getMinValueDivided('CS', 'gameDuration'), getMaxValueDivided('CS', 'gameDuration'))}}>{csPerMinute}</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleGameClick(record.scrimId)}>{"->"}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr style={{backgroundColor: "white"}}>
                <td>Average</td>
                <td></td>
                <td></td>
                <td>{averageValue('killsAndAssists8')}</td>
                <td>{averageValue('deaths8')}</td>
                <td>{(averageValue('goldDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff8')*1).toFixed(0)}</td>
                <td>{averageValue('killsAndAssists14')}</td>
                <td>{averageValue('deaths14')}</td>
                <td>{(averageValue('goldDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('damageShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('goldShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('JungleProximity') * 100).toFixed(2)}%</td>
                <td>{averageValue('kills')}</td>
                <td>{averageValue('deaths')}</td>
                <td>{averageValue('assists')}</td>
                <td>{(averageValue('DamageDealt') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('GoldEarned') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('CS') / averageValue('gameDuration')).toFixed(2)}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        ) : (
          <p>Loading...</p>
        )}

      </div>

      <div>
        {championDictionary && (
          <table id="element1" style={{ backgroundColor: "white", color: "black" }}>
            <thead>
              <tr>
                <th></th>
                <th>Champion</th>
                <th>Games</th>
                <th>WR</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(championDictionary).map(([championName, championData], champIndex) => (
                <tr key={champIndex}>
                  <td><img className="small-image" src={championPhotos[championName]} alt='' /></td>
                  <td>{championName}</td>
                  <td>{championData.matchesPlayed}</td>
                  <td>{championData.winRate}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <button className="screenshot-button" onClick={() => captureScreenshot('element1')}>ScreenShot</button>
      </div>

      <div style={{width: "100%"}}>
        <ChampionPerformanceComponent playerData={filterPlayerData}/>
      </div>

      {filteredProximityData && filteredProximityData.length > 0 && (
        <ProximityPlayerComponent proximityData={filteredProximityData} />
      )}

      { wardData && wardData.length > 0 ? (
        <>
          <div style={{width: "100%", height: "1000px", color: "white", textAlign: "center", alignItems:"center"}}>
          <br></br>
                  <h1>WARDS HEATMAP</h1>
            <HeatmapComponent wardData={wardData} />
          </div>
        </>
      ):(
        <>
        Loading..
        </>
      )}
          <div className='filters'>
        Starting Date
        <input
          type="date"
          value={startDate?.toISOString().split('T')[0]}
          onChange={handleStartDateChange}
        />
        Ending Date
        <input
          type="date"
          value={endDate?.toISOString().split('T')[0]}
          onChange={handleEndDateChange}
        />
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>

      </div>
      <SoloqPageComponent key={name} name={name} filterPatch={filterPatch} startDate={startDate} endDate={endDate}/>
    </div>
  );
};

export default PlayerPage;
