import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './Calendar.css';
import axios from 'axios';

const CalendarPage = () => {
  document.title = "Calendar";
  const [currentDate, setCurrentDate] = useState(moment());
  const [notes, setNotes] = useState({});
  const [startingNotes, setStartingNotes] = useState({});
  useEffect(() => {
    axios.get('https://sanchidataportal.com/api/calendar/?team='+localStorage.getItem("team"))
      .then(response => {
        const fetchedNotes = {};
        response.data.forEach(item => {
          const { id, day, officials, scrims, team } = item;
          fetchedNotes[day] = {
            id,
            officials,
            scrims,
            team,
          };
        });
        setNotes(fetchedNotes);
        setStartingNotes(fetchedNotes);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const renderWeekDays = () => {
    const weekDays = [];
    const startOfWeek = moment().startOf('isoWeek');

    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, 'days').format('ddd');
      weekDays.push(
        <div className="week-day" key={i}>
          {day}
        </div>
      );
    }

    return <div className="week-days">{weekDays}</div>;
  };

// ...

const renderCalendar = () => {
  const calendar = [];
  const startOfWeek = moment(currentDate).subtract(1, 'weeks').startOf('isoWeek');
  const endOfWeek = moment(currentDate).add(2, 'weeks').endOf('isoWeek');

  for (let date = startOfWeek.clone(); date.isBefore(endOfWeek); date.add(1, 'day')) {
    const day = date.format('D');
    const month = date.format('MMMM');
    const formattedDate = date.format('D MMMM'); // Format date as "1 July"
    const isToday = date.isSame(moment(), 'day'); // Check if it's the current day
    const dayClassName = isToday ? 'calendar-day today' : 'calendar-day';
    const noteData = notes[formattedDate];
    const note1 = noteData ? noteData.scrims : '';
    const note2 = noteData ? noteData.officials : '';

    const handleNoteChange = (e, noteType) => {
      const updatedNotes = { ...notes };
      const noteData = updatedNotes[formattedDate] || {};
      noteData[noteType] = e.target.value;
      updatedNotes[formattedDate] = noteData;
      setNotes(updatedNotes);
    };

    calendar.push(
      <div className={dayClassName} >
        <div className="date-container">
          <div className="day">{day}</div>
          <div className="month">{month}</div>
        </div>
        <div className="note-section">
          Scrims
          <textarea
            className="note"
            placeholder="Enter note 1 here"
            value={note1}
            onChange={(e) => handleNoteChange(e, 'scrims')}
          />
        </div>
        <div className="note-section1">
          Officials
          <textarea
            className="note"
            placeholder="Enter note 2 here"
            value={note2}
            onChange={(e) => handleNoteChange(e, 'officials')}
          />
        </div>
      </div>
    );
  }
  return calendar;
};

const handleSaveNotes = () => {
  Object.entries(notes).forEach(([date, note]) => {
    const {id, day, scrims, officials, team } = note;
    const trimmedScrims = scrims !== undefined ? scrims : ' ';
    const trimmedOfficials = officials !== undefined ? officials : ' ';

    if (trimmedScrims !== '' || trimmedOfficials !== '') {


      if (startingNotes[date.toString()]) {
        const noteData = {
          id: id,
          day: date.toString(),
          team: localStorage.getItem("team"),
          scrims: trimmedScrims,
          officials: trimmedOfficials
        };
        // Update existing note
        axios
          .put(`https://sanchidataportal.com/api/calendar/?day=${date.toString()}&team=${localStorage.getItem("team")}`, noteData)
          .then(response => {
            console.log('Note updated successfully:', response.data);
          })
          .catch(error => {
            console.error('Error updating note:', error);
          });
      } else {
        const noteData = {
          day: date.toString(),
          team: localStorage.getItem("team"),
          scrims: trimmedScrims,
          officials: trimmedOfficials
        };
        // Create new note
        axios
          .post('https://sanchidataportal.com/api/calendar/', noteData)
          .then(response => {
            console.log('Note created successfully:', response.data);
          })
          .catch(error => {
            console.error('Error creating note:', error);
          });
      }
    }
  });
};


  return (
    <div style={{color: "white", padding: "5px"}}>
      <h3 style={{textAlign: "center"}}>Calendar</h3>
      <div className="week-days-container">{renderWeekDays()}</div>
      <div className="calendar-container">{renderCalendar()}</div>
      <button onClick={handleSaveNotes}>Save Notes</button>
    </div>
  );
};

export default CalendarPage;
