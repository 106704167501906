import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import roleIcon from './Role';
import { TeamIcons } from './TeamIcons';
import CustomSelect from './CustomSelect';
import "./Team.css";
import { Link } from 'react-router-dom';
import ChampionPoolComponnent from './ChampionPool';
import HeatmapComponent from './WardHeatmap';
import { useSeason } from './SeasonContext';


const TeamComponent = () => {
    const { currentSeasonTime } = useSeason();

    const { teamName } = useParams();
    document.title = teamName;
    const [selectedPatch, setSelectedPatch] = useState([]);
    const [filterPatch, setFilterPatch] = useState([]);
    const [patchOption, setPatchOption] = useState([]);

    const [playerData, setPlayerData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [wardData, setWardData] = useState([]);
    const [filterTeamData, setFilterTeamData] = useState([]);

    const [imageData, setImageData] = useState([]);
    const [filterImageData, setFilterImageData] = useState([]);
    const [teamMatesData, setTeamMatesData] = useState(null);
    const [filteredPlayerChampionData, setFilteredPlayerChampionData] = useState([]);



    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
      axios.get(`https://sanchidataportal.com/api/players/?team=${teamName}&start=${start}&end=${end}`)
      .then((response) => {
        setPlayerData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      axios.get('https://sanchidataportal.com/api/playerwards_official/?team='+teamName+ '&start=' + start + "&end=" + end)
      .then((response) => {
        setWardData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    axios
      .get('https://sanchidataportal.com/api/performances/?team='+teamName+ '&start=' + start + "&end=" + end)
      .then((response) => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        const uniquePatches = [...new Set(response.data.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        setTeamData(sortedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    axios
      .get(`https://sanchidataportal.com/api/image/?team=${teamName}&start=${start}&end=${end}`)
      .then((response) => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setImageData(sortedData);
      })
      .catch((error) => {
        console.error('Error fetching player data:', error);
      });
    axios.get(`https://sanchidataportal.com/api/teammates/?name=${teamName}&start=${start}&end=${end}`)
      .then(response => {
        // Update the team mates data state
        setTeamMatesData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, [currentSeasonTime, teamName]);

  useEffect(() => {
    const filteredPlayersPick = playerData.filter((player) => {
      const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
      return isPatchGood;
    });
    setFilteredPlayerChampionData(filteredPlayersPick)

    const filteredTeamData = teamData.filter((record) => {
      const isPatchGood = filterPatch.length === 0 || filterPatch.includes(record.patch);
      return isPatchGood;
    });

    const filteredImageData = imageData.filter((record) => {
      const isPatchGood = filterPatch.length === 0 || filterPatch.includes(record.patch);
      return isPatchGood;
    });

    setFilterImageData(filteredImageData)
    setFilterTeamData(filteredTeamData)

  }, [playerData, filterPatch, imageData, teamData]);

  const getMaxValue = (property) => {
    return Math.max(...Object.values(filterTeamData).map((player) => player[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(filterTeamData).map((player) => player[property]));
  };

  const handleClick = (teamName) => {
    const searchQuery = encodeURIComponent(teamName); // Encode the value for URL
    window.location.href = `/scrimteam/${searchQuery}`;
  };

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // If the same column is clicked, reverse the sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different column is clicked, set it as the sorted column and default to ascending order
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const compareValues = (key, order = 'asc') => {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // Property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  };

  function averageValue(columnName) {
    const total = filterTeamData.reduce((acc, record) => acc + record[columnName], 0);
    return (total / filterTeamData.length); // You can adjust the number of decimal places
  };



  const handlePatchChange = (selectedOptions) => {
    //setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  function formatGameDuration(duration) {
    const minutes = Math.floor(duration);
    const seconds = Math.round((duration - minutes) * 60);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const maxTopTurretPlates = getMaxValue('TopTurretPlates');
  const maxMidTurretPlates = getMaxValue('MidTurretPlates');
  const maxBotTurretPlates = getMaxValue('BotTurretPlates');

  const maxFirstHerald = getMaxValue('firstHerald');

  const maxFirstDragon = getMaxValue('firstDragon');

  const maxFirstTower = getMaxValue('firstTower');

  const maxTeamGoldDiff8 = getMaxValue('TeamGoldDiff8');
  const minTeamGoldDiff8 = getMinValue('TeamGoldDiff8');

  const maxTeamCsDiff8 = getMaxValue('TeamCsDiff8');
  const minTeamCsDiff8 = getMinValue('TeamCsDiff8');

  const maxTeamExpDiff8 = getMaxValue('TeamExpDiff8');
  const minTeamExpDiff8 = getMinValue('TeamExpDiff8');

  const maxTeamGoldDiff14 = getMaxValue('TeamGoldDiff14');
  const minTeamGoldDiff14 = getMinValue('TeamGoldDiff14');

  const maxTeamCsDiff14 = getMaxValue('TeamCsDiff14');
  const minTeamCsDiff14 = getMinValue('TeamCsDiff14');

  const maxTeamExpDiff14 = getMaxValue('TeamExpDiff14');
  const minTeamExpDiff14 = getMinValue('TeamExpDiff14');

  const maxJungleProximity = getMaxValue('JungleProximity');
  const minJungleProximity = getMinValue('JungleProximity');

  const maxDuoProximity = getMaxValue('DuoProximity');
  const minDuoProximity = getMinValue('DuoProximity');

  const maxDragonsBefore14 = getMaxValue('dragonsBefore14');
  const maxTowersBefore14 = getMaxValue('towersBefore14');

  const getGreenCellColor = (value, maxValue) => {
    const normalizedValue = value / maxValue;
    const hue = 128; // Hue value (green)
    const saturation = 100; // Saturation value (100%)
    const minLightness = 100; // Minimum Lightness value (100%)
    const maxLightness = 50; // Maximum Lightness value (50%)

    const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  };

  const getRedGreenCell = (value, minValue, maxValue) => {
    if (value === 0) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const normalizedValue = value > 0 ? Math.abs(value) / maxValue : Math.abs(value / minValue); // Use absolute value

      // Define hue for the color gradient (e.g., green or red)
      const hue = value > 0 ? 120 : 0; // Green for positive, Red for negative

      // Interpolate lightness between 100% and 50% based on normalized value
      const lightness = 100 - 50 * normalizedValue;

      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  const getRedGreenCellno0 = (value, minValue, maxValue) => {
    if (value === (minValue + maxValue) / 2) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const midpoint = (minValue + maxValue) / 2;
      const normalizedValue =
        value > midpoint
          ? (value - midpoint) / (maxValue - midpoint)
          : (-value + midpoint) / (minValue + midpoint);

      // Define hue for the color gradient (e.g., green or red)
      const hue = value > midpoint ? 120 : 0; // Green for positive, Red for negative

      // Interpolate lightness between 100% and 50% based on normalized value
      const lightness = 100 - 50 * normalizedValue;

      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };


  const handleMatchClick = (gameId) => {
    window.location.href = `/match/${gameId}`;
  };
  const handleScoutingClick = (team) => {
    window.location.href = `/scouting/${team}`;
  };

  return (
    <div>
      <div style={{width:"100%", textAlign: "center", color: "white"}}>
      Officials
      </div>
      <div style={{display: 'flex', width: "100%", justifyContent: 'space-between', backgroundColor: "white", marginBottom: "10px", alignItems: "center", textAlign: "center",borderBottom: "2px solid rgb(0, 242, 255)" }}>
        <button onClick={() => handleScoutingClick(teamName)}>GO TO SCOUTING</button>
        <div>
          {teamName}
          <img className="small-image" src={TeamIcons[teamName]} alt=""/>
        </div>
        <button onClick={() => handleClick(teamName)}>GO TO SCRIMS</button>
      </div>

      <div style={{alignItems: "center"}}>
        {teamMatesData && (
          <div className="teammates-container">
            <div  style={{alignItems: "center", display: 'flex', justifyContent: 'center'}}  >
              {teamMatesData.map(teammate => (
                <a
                key={teammate.summonerName}
                href={`/player/${encodeURIComponent(teammate.summonerName)}`}
                className="teammate-link-2"
                >
                  <div className="teammate">
                    <img
                      src={roleIcon[teammate.Role]}
                      alt={`Role ${teammate.Role} Icon`}
                      className="role-icon"
                      />
                    <span className="teammate-name-2">{teammate.summonerName}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className='filters'>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
        side
      </div>
      <div className='team-perfromance-by-game'>
        {filterTeamData ? (
          <table className='team-perfromance-by-game-table'>
            <thead>
              <tr>
                <th onClick={() => handleSort('side')}>Side</th>
                <th onClick={() => handleSort('GameDuration')}>Time</th>
                <th onClick={() => handleSort('EnemyTeamName')}>Enemy</th>
                <th onClick={() => handleSort('winner')}>Winner</th>
                <th onClick={() => handleSort('patch')}>Patch</th>
                <th onClick={() => handleSort('firstHerald')}>First Herald</th>
                <th onClick={() => handleSort('firstDragon')}>First Dragon</th>
                <th onClick={() => handleSort('firstTower')}>First Tower</th>
                <th onClick={() => handleSort('firstBlood')}>First Blood</th>
                <th onClick={() => handleSort('firstVoidgrub')}>First VoidGrubs</th>
                <th onClick={() => handleSort('voidGrubKilled')}>VoidGrub Killed</th>
                <th onClick={() => handleSort('TopTurretPlates')}>Top Plates</th>
                <th onClick={() => handleSort('MidTurretPlates')}>Mid Plates</th>
                <th onClick={() => handleSort('BotTurretPlates')}>Bot Plates</th>
                <th onClick={() => handleSort('TeamGoldDiff8')}>GD@8</th>
                <th onClick={() => handleSort('TeamCsDiff8')}>CSD@8</th>
                <th onClick={() => handleSort('TeamExpDiff8')}>XPD@8</th>
                <th onClick={() => handleSort('TeamGoldDiff14')}>GD@14</th>
                <th onClick={() => handleSort('TeamCsDiff14')}>CSD@14</th>
                <th onClick={() => handleSort('TeamExpDiff14')}>XPD@14</th>
                <th onClick={() => handleSort('dragonsBefore14')}>Dragons @14</th>
                <th onClick={() => handleSort('towersBefore14')}>Towers @14</th>
                <th onClick={() => handleSort('JungleProximity')}>Jungle %</th>
                <th onClick={() => handleSort('DuoProximity')}>Duo %</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterTeamData
                .slice()
                .sort(compareValues(sortedColumn, sortDirection)) // Sort the data
                .map((record, index) => (
                  <tr key={index}>
                    <td style={{backgroundColor: record.side === "blue" ? 'rgb(153, 153, 234)' : 'rgb(255, 186, 186)'}}>{record.side}</td>
                    <td>{formatGameDuration(record.GameDuration)}</td>
                    <Link  to={`/team/${record.EnemyTeamName}`} style={{textDecoration: "none", color: 'inherit', textAlign: 'left', alignItems: 'center', display: 'block'}}>
                      <td style={{ cursor: 'pointer', textAlign: 'left' }}>
                        <img className="small-image" src={TeamIcons[record.EnemyTeamName]} alt=""/>  {record.EnemyTeamName}
                      </td>
                    </Link>
                    <td>{record.winner ? 'Yes' : 'No'}</td>
                    <td>{record.patch}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstHerald, maxFirstHerald) }}>{record.firstHerald.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstDragon, maxFirstDragon) }}>{record.firstDragon.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstTower, maxFirstTower) }}>{record.firstTower.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstBlood, 1) }}>{record.firstBlood.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstVoidgrub, 1) }}>{record.firstVoidgrub.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.voidGrubKilled, 6) }}>{record.voidGrubKilled.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.TopTurretPlates, maxTopTurretPlates) }}>{record.TopTurretPlates.toFixed(2)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.MidTurretPlates, maxMidTurretPlates) }}>{record.MidTurretPlates.toFixed(2)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.BotTurretPlates, maxBotTurretPlates) }}>{record.BotTurretPlates.toFixed(2)}</td>

                    <td style={{ backgroundColor: getRedGreenCell(record.TeamGoldDiff8, minTeamGoldDiff8, maxTeamGoldDiff8)}}>{record.TeamGoldDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamCsDiff8, minTeamCsDiff8, maxTeamCsDiff8)}}>{record.TeamCsDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamExpDiff8, minTeamExpDiff8, maxTeamExpDiff8)}}>{record.TeamExpDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamGoldDiff14, minTeamGoldDiff14, maxTeamGoldDiff14)}}>{record.TeamGoldDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamCsDiff14, minTeamCsDiff14, maxTeamCsDiff14)}}>{record.TeamCsDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamExpDiff14, minTeamExpDiff14, maxTeamExpDiff14)}}>{record.TeamExpDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.dragonsBefore14, maxDragonsBefore14) }}>{record.dragonsBefore14.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.towersBefore14, maxTowersBefore14) }}>{record.towersBefore14.toFixed(1)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.JungleProximity, minJungleProximity, maxJungleProximity)}}>{(record.JungleProximity.toFixed(4)*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.DuoProximity, minDuoProximity, maxDuoProximity)}}>{(record.DuoProximity.toFixed(4)*100).toFixed(2)}%</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleMatchClick(record.scrimId)}>{"->"}</td>
                  </tr>
              ))}
            </tbody>
            <tfoot style={{borderTop: "1px solid black", backgroundColor: "white"}}>
              <tr style={{borderTop: "1px solid black"}}>
                <td>Average</td>
                <td></td> {/* Leave this empty for the Game Duration column */}
                <td></td> {/* Leave this empty for text columns */}
                <td>{(averageValue('winner')*100).toFixed(2)}%</td> {/* Leave this empty for text columns */}
                <td></td> {/* Leave this empty for Winner column */}
                <td>{(averageValue('firstHerald')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstDragon')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstTower')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstBlood')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstVoidgrub')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('voidGrubKilled')).toFixed(2)}</td> {/* Leave this empty for non-numeric columns */}
                <td>{averageValue('TopTurretPlates').toFixed(2)}</td>
                <td>{averageValue('MidTurretPlates').toFixed(2)}</td>
                <td>{averageValue('BotTurretPlates').toFixed(2)}</td>
                <td>{averageValue('TeamGoldDiff8').toFixed(0)}</td>
                <td>{averageValue('TeamCsDiff8').toFixed(0)}</td>
                <td>{averageValue('TeamExpDiff8').toFixed(0)}</td>
                <td>{averageValue('TeamGoldDiff14').toFixed(0)}</td>
                <td>{averageValue('TeamCsDiff14').toFixed(0)}</td>
                <td>{averageValue('TeamExpDiff14').toFixed(0)}</td>
                <td>{averageValue('dragonsBefore14').toFixed(2)}</td>
                <td>{averageValue('towersBefore14').toFixed(2)}</td>
                <td>{(averageValue('JungleProximity')*100).toFixed(2)}%</td>
                <td>{(averageValue('DuoProximity')*100).toFixed(2)}%</td>
              </tr>
            </tfoot>
          </table>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <ChampionPoolComponnent playerData={filteredPlayerChampionData}/>
      { wardData ? (
        <>
          <div style={{width: "100%", height: "1000px", color: "white", textAlign: "center", alignItems:"center"}}>
          <br></br>
                  <h1>WARDS HEATMAP</h1>
            <HeatmapComponent wardData={wardData} />
          </div>
        </>
      ):(
        <>
        Loading..
        </>
      )}
      <p style={{color: "white", textAlign: "center", fontSize: "16px"}}>Level 1</p>
      <div className='team-level1'>
        <div style={{border: "2px solid rgb(0, 242, 255)", width: "100%", display: "flex", flexWrap: 'wrap'}}>
        {filterImageData? (
          <>
          {filterImageData.map(match => (
            <div className='imagedata-wrapper' key={match.gameId}>
              <div style={{textAlign: "center"}}>
                {match.team1} vs {match.team2} {match.patch}
              </div>
              <div>
                <img className='big-image-level1' src={match.seconds_30} alt=''/>
                <img className='big-image-level1' src={match.seconds_60} alt=''/>
                <img className='big-image-level1' src={match.seconds_90} alt=''/>
              </div>
            </div>
          ))}
          </>
        ):(
            <>
            Loading
            </>
        )}
        </div>
      </div>

    </div>
            );
          };

export default TeamComponent;
