import role0Icon from '../role_photos/0.png';
import role1Icon from '../role_photos/1.png';
import role2Icon from '../role_photos/2.png';
import role3Icon from '../role_photos/3.png';
import role4Icon from '../role_photos/4.png';

const roleIcon = {
  0: role0Icon,
  1: role1Icon,
  2: role2Icon,
  3: role3Icon,
  4: role4Icon,
};

export default roleIcon;