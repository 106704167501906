import React from 'react';
import roleIcon from './Role';
import { championPhotos } from './ChampionsIcon';

const ProximityPlayerComponent = ({ proximityData }) => {

  const averages = {
    TopProximity: calculateAverage('TopProximity'),
    JglProximity: calculateAverage('JglProximity'),
    MidProximity: calculateAverage('MidProximity'),
    BotProximity: calculateAverage('BotProximity'),
    SupProximity: calculateAverage('SupProximity'),
  };

  const championRoleAverages = calculateChampionRoleAverages();

  function calculateAverage(role) {
    const total = proximityData.reduce((sum, element) => sum + element[role], 0);
    return total / proximityData.length;
  }

  function calculateChampionRoleAverages() {
    const averages = {};
    proximityData.forEach(element => {
      if (!averages[element.championName]) {
        averages[element.championName] = {
          TopProximity: 0,
          JglProximity: 0,
          MidProximity: 0,
          BotProximity: 0,
          SupProximity: 0,
          count: 0,
        };
      }
      averages[element.championName].TopProximity += element.TopProximity;
      averages[element.championName].JglProximity += element.JglProximity;
      averages[element.championName].MidProximity += element.MidProximity;
      averages[element.championName].BotProximity += element.BotProximity;
      averages[element.championName].SupProximity += element.SupProximity;
      averages[element.championName].count += 1;
    });

    Object.keys(averages).forEach(championName => {
      averages[championName].TopProximity /= averages[championName].count;
      averages[championName].JglProximity /= averages[championName].count;
      averages[championName].MidProximity /= averages[championName].count;
      averages[championName].BotProximity /= averages[championName].count;
      averages[championName].SupProximity /= averages[championName].count;
    });

    return averages;
  }

  return (
    <div style={{ width: '100%', display: 'flex', color: 'white', textAlign: 'center' }}>
      <div style={{ width: '50%', paddingLeft: '50px', paddingRight: '50px' }}>
        Proximity
        <table style={{ backgroundColor: 'white', color: 'black' }}>
          <thead>
            <tr>
              <th>
                Champion
              </th>
              <th>
                <img src={roleIcon[0]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[1]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[2]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[3]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[4]} className="small-image" alt="" />
              </th>
            </tr>
          </thead>
          <tbody>
            {proximityData.map((element, index) => (
              <tr key={index}>
                <td style={{ alignItems: "left", textAlign: "left" }}><img src={championPhotos[element.championName]} className="small-image" alt="" />{element.championName}</td>
                <td>{element.TopProximity}%</td>
                <td>{element.JglProximity}%</td>
                <td>{element.MidProximity}%</td>
                <td>{element.BotProximity}%</td>
                <td>{element.SupProximity}%</td>
              </tr>
            ))}
            <tr>
              <td>Average</td>
              <td>{averages.TopProximity.toFixed(2)}%</td>
              <td>{averages.JglProximity.toFixed(2)}%</td>
              <td>{averages.MidProximity.toFixed(2)}%</td>
              <td>{averages.BotProximity.toFixed(2)}%</td>
              <td>{averages.SupProximity.toFixed(2)}%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ width: '50%', paddingLeft: '50px', paddingRight: '50px' }}>
        Proximity Average by Champion and Role
        <table style={{ backgroundColor: 'white', color: 'black' }}>
          <thead>
            <tr>
              <th>
                Champion
              </th>
              <th>
                <img src={roleIcon[0]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[1]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[2]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[3]} className="small-image" alt="" />
              </th>
              <th>
                <img src={roleIcon[4]} className="small-image" alt="" />
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(championRoleAverages).map((championName, index) => (
              <tr key={index}>
                <td style={{ alignItems: "left", textAlign: "left" }}><img src={championPhotos[championName]} className="small-image" alt="" />{championName}</td>
                <td>{championRoleAverages[championName].TopProximity.toFixed(2)}%</td>
                <td>{championRoleAverages[championName].JglProximity.toFixed(2)}%</td>
                <td>{championRoleAverages[championName].MidProximity.toFixed(2)}%</td>
                <td>{championRoleAverages[championName].BotProximity.toFixed(2)}%</td>
                <td>{championRoleAverages[championName].SupProximity.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProximityPlayerComponent;
