import React, { useEffect, useState } from 'react';
import './Player.css';
import heatmap from 'heatmap.js';
import mapPhotsDictionary from './MapData';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';

const HeatmapComponent = ({ wardData }) => {
    const [timeRange, setTimeRange] = useState([0, 60]);
    const [selectedTeam, setSelectedTeam] = useState('any');
    const [selectedWard, setSelectedWard] = useState('any');
    const [heatmapInstance, setHeatmapInstance] = useState(null);

    useEffect(() => {
      const container = document.getElementById('heatmap-container');
      const image = new Image();
      image.src = mapPhotsDictionary["SummonersRift"];

      image.onload = () => {
        if (!heatmapInstance) {
          const newHeatmapInstance = heatmap.create({
            container,
            radius: 25,
            visible: true,
          });
          setHeatmapInstance(newHeatmapInstance);
        }
      };
    }, [heatmapInstance]);

    useEffect(() => {
      if (!heatmapInstance) return;

      const container = document.getElementById('heatmap-container');
      const image = container.querySelector('img');

      if (!image.complete) {
        image.onload = () => updateHeatmap();
      } else {
        updateHeatmap();
      }

      function updateHeatmap() {
        const filteredData = wardData.filter(ward => {
          const isWithinTimeRange = ward.placeTime >= timeRange[0] * 60 && ward.placeTime <= timeRange[1] * 60;
          const isMatchingTeam = selectedTeam === 'any' || ward.side === selectedTeam;
          const isMatchingWard = selectedWard === 'any' || ward.type === selectedWard;
          return isWithinTimeRange && isMatchingTeam && isMatchingWard;
        });

        const adjustedData = filteredData.map(ward => ({
          x: ((ward.x - (-120)) / (14870 - (-120)) * image.width).toFixed(0),
          y: (image.height - ((ward.y - (-120)) / (14980 - (-120)) * image.height)).toFixed(0),
          value: 1,
        }));

        const uniqueScrimIds = new Set(filteredData.map(ward => ward.scrimId));
        const maxUniqueScrimId = uniqueScrimIds.size;

        const data = {
          max: (maxUniqueScrimId / 2).toFixed(0),
          data: adjustedData,
        };

        heatmapInstance.setData(data);
        heatmapInstance.repaint();

        const dynamicText = `${timeRange[0]} - ${timeRange[1]}`;
        const dynamicText2 = `${selectedTeam}, ${selectedWard}`;
        const textAnnotations = [
          { x: 50, y: 0, text: dynamicText },
          { x: 0, y: 0, text: dynamicText2 },
        ];

        document.querySelectorAll('.heatmap-text-annotation').forEach(el => el.remove());

        textAnnotations.forEach(annotation => {
          const textElement = document.createElement("div");
          textElement.classList.add('heatmap-text-annotation');
          textElement.style.position = "absolute";
          textElement.style.left = `${annotation.x}%`;
          textElement.style.top = `${annotation.y}%`;
          textElement.style.color = "white";
          textElement.style.zIndex = "1000";
          textElement.textContent = annotation.text;
          container.appendChild(textElement);
        });
      }
    }, [wardData, timeRange, selectedTeam, heatmapInstance, selectedWard]);

    const handleTimeRangeChange = (newTimeRange) => {
      setTimeRange(newTimeRange);
    };

    const captureScreenshot = (table) => {
      const element = document.getElementById(table);

      html2canvas(element)
        .then((canvas) => {
          const image = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'screenshot.png';
          link.href = image;
          link.click();
        })
        .catch(err => {
          console.error('Error capturing screenshot', err);
        });
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <div id="heatmap-container" style={{ position: 'relative' }}>
          <img src={mapPhotsDictionary["SummonersRift"]} alt="" />
        </div>
        <button className="screenshot-button" onClick={() => captureScreenshot('heatmap-container')}>ScreenShot</button>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
          <div>
            <label htmlFor="teamSelect">Select side:</label>
            <select id="teamSelect" value={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
              <option value="any">Any</option>
              <option value="blue">Blue</option>
              <option value="red">Red</option>
            </select>
          </div>
          <div>
            <label htmlFor="teamSelect1">Ward type:</label>
            <select id="teamSelect1" value={selectedWard} onChange={e => setSelectedWard(e.target.value)}>
              <option value="any">Any</option>
              <option value="control">Control</option>
              <option value="yellowTrinket">Yellow</option>
              <option value="sight">Blue</option>
            </select>
          </div>
          <span>{timeRange[0]} - {timeRange[1]}</span>
          <Slider
            range
            min={0}
            max={60}
            step={1}
            value={timeRange}
            onChange={handleTimeRangeChange}
            style={{
              width: '50%',
              padding: '10px',
              background: 'linear-gradient(to right, #FF5733, #FF5733)',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          />
          <span>{timeRange[0]} - {timeRange[1]}</span>
        </div>
      </div>
    );
  };

  export default HeatmapComponent;
