import React from 'react';
import roleIcon from './Role';
const ProximityComponent = ({proximityData}) => {
    // Filter data for the blue side
    const blueSideData = proximityData.filter(element => element.side === 'blue');

    // Filter data for the red side
    const redSideData = proximityData.filter(element => element.side === 'red');

  return (
    <div style={{width: "100%", display: "flex", color: "white", textAlign: "center"}}>
        <div style={{width: "50%", paddingLeft: "50px", paddingRight: "50px"}}>
            Blue
            <table style={{backgroundColor: "white", color: 'black'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th><img src={roleIcon[0]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[1]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[2]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[3]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[4]} className='small-image' alt=''/></th>
                    </tr>
                </thead>
                <tbody>
                    {blueSideData.map((element, index) => (
                    <tr key={index}>
                        <td><img src={roleIcon[element.role]} className='small-image' alt=''/></td>
                        <td>{element.TopProximity}%</td>
                        <td>{element.JglProximity}%</td>
                        <td>{element.MidProximity}%</td>
                        <td>{element.BotProximity}%</td>
                        <td>{element.SupProximity}%</td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div style={{width: "50%", paddingLeft: "50px", paddingRight: "50px"}}>
            Red
            <table style={{backgroundColor: "white", color: 'black'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th><img src={roleIcon[0]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[1]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[2]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[3]} className='small-image' alt=''/></th>
                        <th><img src={roleIcon[4]} className='small-image' alt=''/></th>
                    </tr>
                </thead>
                <tbody>
                    {redSideData.map((element, index) => (
                    <tr key={index}>
                        <td><img src={roleIcon[element.role]} className='small-image' alt=''/></td>
                        <td>{element.TopProximity}%</td>
                        <td>{element.JglProximity}%</td>
                        <td>{element.MidProximity}%</td>
                        <td>{element.BotProximity}%</td>
                        <td>{element.SupProximity}%</td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>

    </div>
  );
};

export default ProximityComponent;