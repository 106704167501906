import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Sidebar from './Sidebar';
import HomePage from './components/HomePage';
import './App.css'; // Import the CSS file for styling
import CalendarPage from './components/Calendar';
import MultiTable from './components/Drafts';
import TierlistForm from './components/Tierlist';
import ScrimsModule from './components/Scrims';
import PlayerPage from './components/Player';
import Players from './components/Players';
import Teams from './components/Teams';
import MatchHistoryComponent from './components/MatchHistory';
import ScrimHistoryComponent from './components/ScrimHistory';
import MatchComponent from './components/Match';
import ScrimComponent from './components/Scrim';
import TeamComponent from './components/Team';
import DraftsComponent from './components/TeamDraft';
import TeamScrimComponent from './components/TeamScrim';
import ChampionComponent from './components/Champion';
import ChampionsComponent from './components/Champions';
import PlayerPageScrim from './components/PlayerScrim';
import SoloqPageComponent from './components/SoloqPage';
import OtherComponent from './components/Other';
import SupportItemsComponent from './components/SupportItems';
import ScrimsChampionComponent from './components/ScrimsChampionStats';
import PickBanByPatchComponent from './components/PickBanByPatch';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MapComponent from './components/Map';
import MapScrimComponent from './components/MapScrim';
import Cookies from 'js-cookie';
import { SeasonProvider } from './components/SeasonContext';
import ScoutingComponent from './components/Scouting';
import EditableTable from './components/Draft';
import EditScrimComponent from './components/EditScrim';
import TeamSoloqComponent from './components/TeamSoloqPage';
import LeaderboardComponent from './components/Leaderboard';
import ChampionRoleComponent from './components/ChampionRole';
import ChampPoolComponent from './components/ChampPoolComparison';
function App() {
  const [loggedIn, setLoggedIn] = useState(Cookies.get('loggedIn') === 'true');
  const [security, setSecurity] = useState(Cookies.get('security'));
  useEffect(() => {
    if (!loggedIn) {
      // If not logged in, remove cookies for login-related data
      Cookies.remove('loggedIn');
      Cookies.remove('security');
      Cookies.remove('team');
    }
  }, [loggedIn]);

  return (
    <GoogleOAuthProvider clientId="35952612290-cq2dp4s7b5cfqtceka6icg505lml9jlu.apps.googleusercontent.com">
      <SeasonProvider>
        <Router>
          <AppContent loggedIn={loggedIn} setLoggedIn={setLoggedIn} security={security} />
        </Router>
      </SeasonProvider>
    </GoogleOAuthProvider>
  );
}

function AppContent({ loggedIn, setLoggedIn, security }) {
  const location = useLocation();
  const isTeamSoloqRoute = location.pathname.startsWith("/teamsoloq/");

  return (
    <div className="app-container">
      {!isTeamSoloqRoute && <Sidebar />}
      <div className="content">
        <Routes>
          <Route path="/teamsoloq/:teamName" element={<TeamSoloqComponent />} />
          {loggedIn ? (
            <>
              <Route path="/login" element={<LoginPage setLoggedIn={setLoggedIn} />} />
              <Route path="/drafts" element={<EditableTable />} />
              <Route path="/tierlist" element={<TierlistForm />} />
              <Route path="/championRole" element={<ChampionRoleComponent />} />
              {security > 0 && (
                <>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage setLoggedIn={setLoggedIn} />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/calendar" element={<CalendarPage />} />
              <Route path="/scrims" element={<ScrimsModule />} />
              <Route path="/players" element={<Players />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/champions" element={<ChampionsComponent />} />
              <Route path="/championRole" element={<ChampionRoleComponent />} />
              <Route path="/matchhistory" element={<MatchHistoryComponent />} />
              <Route path="/scrimhistory" element={<ScrimHistoryComponent />} />
              <Route path="/player/:name" element={<PlayerPage />} />
              <Route path="/playerscrim/:name" element={<PlayerPageScrim />} />
              <Route path="/match/:gameId" element={<MatchComponent />} />
              <Route path="/scrim/:gameId" element={<ScrimComponent />} />
              <Route path="/team/:teamName" element={<TeamComponent />} />
              <Route path="/scrimteam/:teamName" element={<TeamScrimComponent />} />
              <Route path="/scouting/:teamName" element={<ScoutingComponent />} />
              <Route path="/champions/:champion" element={<ChampionComponent />} />
              <Route path="/teamsDrafts" element={<DraftsComponent />} />
              <Route path="/credits" element={<TierlistForm />} />
              <Route path="/other" element={<OtherComponent />} />
              <Route path="/supportitems" element={<SupportItemsComponent />} />
              <Route path="/scrimchampionstats" element={<ScrimsChampionComponent />} />
              <Route path="/pickbanbypatch" element={<PickBanByPatchComponent />} />
              <Route path="/map/:gameId" element={<MapComponent />} />
              <Route path="/mapScrim/:gameId" element={<MapScrimComponent />} />
              <Route path="/editScrim/:gameId" element={<EditScrimComponent />} />
              <Route path="/tierlist" element={<TierlistForm />} />
              <Route path="/scouting/:teamName" element={<ScoutingComponent />} />
              <Route path="/draft" element={<EditableTable />} />
              <Route path="/leaderboard" element={<LeaderboardComponent />} />
              <Route path="/champpoolcomparison" element={<ChampPoolComponent />} />
              <Route path="/scouting/:teamName" element={<ScoutingComponent />} />
              </>
              )}
            </>
          ) : (
            <>
              <Route path="*" element={<LoginPage setLoggedIn={setLoggedIn} />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
}

export default App;
